import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, TextField, Typography } from "@material-ui/core";

class FormGenerator extends Component {
  static protoTypes = {
    ABI: PropTypes.array.isRequired,
    formSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {};

  state = {
    formInputs: []
  };

  componentDidMount() {
    const eventForm = this.getFormEvent();

    const newArray = eventForm.inputs.map(input => ({
      name: input.name,
      value: ""
    }));

    this.setState({
      formInputs: newArray
    });
  }

  renderTextFields = () => {
    const eventForm = this.getFormEvent();

    return eventForm.inputs.map(input => {
      const name = input.name;
      return (
        <TextField
          key={name}
          id={name}
          label={name}
          autoComplete={name}
          type="text"
          onChange={event => {
            let newFormInputs = this.state.formInputs;
            const foundIndex = newFormInputs.findIndex(
              object => object.name === name
            );
            newFormInputs[foundIndex].value = event.target.value;
            this.setState({
              formInputs: newFormInputs
            });
          }}
          fullWidth
          margin="normal"
        />
      );
    });
  };

  handleFormSubmit = event => {
    event.preventDefault();

    const { formSubmit } = this.props;

    const values = this.state.formInputs.map(input => input.value);
    formSubmit({ values });
  };

  getFormEvent = () => {
    const { ABI } = this.props;

    return ABI.find(o => o.name === "Form" && o.type === "event");
  };

  render() {
    if (!this.getFormEvent()) {
      return <Typography>ABI is not valid: Missing Form Event.</Typography>;
    }

    return (
      <form onSubmit={this.handleFormSubmit}>
        {this.renderTextFields()}
        <Button
          type="submit"
          color="secondary"
          style={{ marginTop: 20 }}
          fullWidth
          variant="contained"
        >
          Run on BLOCKxStudio Test Network
        </Button>
      </form>
    );
  }
}

export default FormGenerator;
