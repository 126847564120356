import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../shared/Loading";
import { invitesActions } from "../../actions";

import { getRoleById } from "../../constants/Roles";

const styles = {};

class InvitesList extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
    filterRoles: PropTypes.arrayOf(PropTypes.string),
    filterOrganizationId: PropTypes.string
  };

  static defaultProps = {
    filterRoles: null,
    filterOrganizationId: null
  };

  rehydrateState() {
    this.props.getInvites();
  }

  componentDidMount() {
    this.rehydrateState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isDeletingInvite || prevProps.isSendingInvite) {
      this.rehydrateState();
    }
  }

  render() {
    const { invites, isGettingInvites, deleteInvite } = this.props;

    if (isGettingInvites) {
      return <Loading text="Loading Invites" />;
    }

    if (!invites || invites.length < 1) {
      return (
        <div className={this.props.className}>
          <Typography variant="h5" gutterBottom>
            Sent Invites
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            No Invites to List
          </Typography>
        </div>
      );
    }

    if (!this.props.filterOrganizationId) {
      // Show invites whose organizationId is null
    } else {
      // Show invites whose organizationId matches this.props.organizationId
    }

    return (
      <div className={this.props.className}>
        <Typography variant="h5" gutterBottom>
          Sent Invites
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Role ID</TableCell>
              <TableCell>Role Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invites &&
              invites
                .filter(invite => this.props.filterRoles.includes(invite.role))
                .filter(invite => {
                  if (
                    !this.props.filterOrganizationId &&
                    !invite.organizationId
                  ) {
                    return true;
                  }
                  return (
                    this.props.filterOrganizationId === invite.organizationId
                  );
                })
                .map(invite => {
                  return (
                    <TableRow key={invite._id}>
                      <TableCell>{invite.email}</TableCell>
                      <TableCell>{invite.role}</TableCell>
                      <TableCell>{getRoleById(invite.role).name}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => deleteInvite(invite._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    invites: state.invites.invites,
    isGettingInvites: state.invites.isGettingInvites,
    isDeletingInvite: state.invites.isDeletingInvite,
    isSendingInvite: state.invites.isSendingInvite
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInvites: invitesActions.getInvites,
      deleteInvite: invitesActions.deleteInvite
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InvitesList));
