import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link, Route, Switch, Redirect } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Hidden,
  Typography
} from "@material-ui/core";
import GigWorkerProfile from "./GigWorkerProfile";
import GigListings from "./GigListings";
import GigDetails from "./GigDetails";

const styles = {
  root: {},
  logoSection: {
    backgroundColor: "#f6f6f6"
  },
  logo: {
    padding: "40px 0px",
    textAlign: "center"
  },
  workflowSection: {},
  tabs: {
    flexGrow: 1
  },
  workflowContent: {
    maxWidth: 1400,
    margin: "0 auto"
  },
  restartSection: {
    textAlign: "center"
  },
  restartButton: {
    margin: "20px 10px 10px"
  }
};

class Workflow extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  state = {
    value: "/workflows/worker-profile/profile"
  };

  componentDidMount() {
    const { pathname } = this.props;

    // When the first tab does not match the current pathname...
    if (this.state.value !== pathname) {
      this.updateTabSelection();
    }
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props;

    // When the pathname changes...
    if (prevProps.pathname !== pathname) {
      this.updateTabSelection();
    }
  }

  updateTabSelection() {
    const { pathname } = this.props;

    const validValues = [
      "/workflows/worker-profile/profile",
      "/workflows/worker-profile/gigs"
    ];

    if (validValues.includes(pathname)) {
      // Select the tab matching the current pathname
      this.setState({ value: pathname });
    } else {
      // Otherwise, select none of the tabs
      this.setState({ value: false });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.logoSection}>{this.logo()}</div>
        <div className={classes.workflowSection}>
          {this.tabs()}
          <div className={classes.workflowContent}>
            <Switch>
              <Redirect
                exact
                from="/workflows/worker-profile/"
                to="/workflows/worker-profile/profile"
              />
              <Route
                path="/workflows/worker-profile/profile"
                component={GigWorkerProfile}
              />
              <Route
                path="/workflows/worker-profile/gigs"
                component={GigListings}
              />
              <Route
                path="/workflows/worker-profile/gig-details"
                component={GigDetails}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  tabs() {
    const { value } = this.state;
    const { classes } = this.props;

    const tabs = [
      {
        label: "Profile",
        link: "/workflows/worker-profile/profile"
      },
      {
        label: "Job Listings",
        link: "/workflows/worker-profile/gigs"
      }
    ];

    const mobileTabs = (
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="primary"
        fullWidth
        scrollable
      >
        {tabs.map((tab, i) => (
          <Tab
            key={i}
            value={tab.link}
            label={tab.label}
            component={Link}
            to={tab.link}
          />
        ))}
      </Tabs>
    );

    const desktopTabs = (
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((tab, i) => (
          <Tab
            key={i}
            value={tab.link}
            label={tab.label}
            component={Link}
            to={tab.link}
          />
        ))}
      </Tabs>
    );

    return (
      <AppBar color="inherit" position="static" elevation={0}>
        <Toolbar>
          <Hidden mdUp>{mobileTabs}</Hidden>
          <Hidden smDown>{desktopTabs}</Hidden>
        </Toolbar>
      </AppBar>
    );
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  logo() {
    const { classes } = this.props;

    return (
      <div className={classes.logo}>
        <Typography variant="h4">Worker</Typography>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Workflow));
