import { gigWorkerConstants } from "../actions";

const GIGWORKER_INITIAL = {
  worker: {
    _id: "1",
    name: "Jonathan Park",
    phone: "+14255551032",
    status: 1,
    prefs: {
      location: "location data",
      time: "time data",
      jobType: "job type data"
    }
  },
  gig: {
    id: 1,
    dateTime: "6/8/2117 2am",
    company: "Bed Bath & Beyond Inc.",
    task: "Heavy Lifting",
    location: "190 Lofo Park",
    rate: "$15.79",
    workerId: "1",
    details:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
  },
  workers: [
    {
      _id: "0",
      name: "Eliza Greene",
      phone: "+13735558865",
      status: 0
    },
    {
      _id: "1",
      name: "Jonathan Park",
      phone: "+14255551032",
      status: 1,
      prefs: {
        location: "location data",
        time: "time data",
        jobType: "job type data"
      }
    },
    {
      _id: "2",
      name: "Rachel Cowdan",
      phone: "+12065559214",
      status: 1,
      prefs: {
        location: "location data",
        time: "time data",
        jobType: "job type data"
      }
    },
    {
      _id: "3",
      name: "Naya Atwood",
      phone: "+13735558865",
      status: -1
    },
    {
      _id: "4",
      name: "Catherine Lowe",
      phone: "+14255551032",
      status: 1,
      prefs: {
        location: "location data",
        time: "time data",
        jobType: "job type data"
      }
    },
    {
      _id: "5",
      name: "Michael Stanley",
      phone: "+14255558222",
      status: 1,
      prefs: {
        location: "location data",
        time: "time data",
        jobType: "job type data"
      }
    }
  ],
  gigs: [
    {
      id: 1,
      dateTime: "6/8/2117 2am",
      company: "Bed Bath & Beyond Inc.",
      task: "Heavy Lifting",
      location: "190 Lofo Park",
      rate: "$15.79",
      workerId: "1",
      status: "Complete",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
      id: 2,
      dateTime: "3/23/2081 9am",
      company: "Broadwing, Inc.",
      task: "General Handyman",
      location: "467 Karra Way",
      rate: "$22.50",
      workerId: "8",
      status: "In Progress",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
      id: 3,
      dateTime: "7/27/2062 5pm",
      company: "Intuit Inc.",
      task: "Dog sitting",
      location: "1355 Agako Avenue",
      rate: "$59.17",
      workerId: "2",
      status: "Complete",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
      id: 4,
      dateTime: "4/9/2107 2am",
      company: "Phillips Petroleum Co",
      task: "Heavy Lifting",
      location: "664 Navij Turnpike",
      rate: "$34.29",
      workerId: "6",
      status: "In Progress",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
      id: 5,
      dateTime: "3/19/2044 2am",
      company: "National Fuel Gas Company",
      task: "Mounting and installation",
      location: "797 Gugwa Pike",
      rate: "$44.48",
      workerId: "4",
      status: "Pending Confirmation",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
      id: 6,
      dateTime: "7/28/2096 9pm",
      company: "Convergys Corp.",
      task: "Mounting and installation",
      location: "906 Edje Extension",
      rate: "$8.57",
      workerId: "3",
      status: "In Progress",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
      id: 7,
      dateTime: "6/14/2054 5pm",
      company: "Enterprise Products Partners L.P.",
      task: "General Handyman",
      location: "528 Gibju Square",
      rate: "$9.49",
      workerId: "1",
      status: "Expired",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
      id: 8,
      dateTime: "11/20/2090 12pm",
      company: "Regions Financial Corp.",
      task: "General Handyman",
      location: "1619 Tejhib Parkway",
      rate: "$31.07",
      workerId: "2",
      status: "Pending Confirmation",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
      id: 9,
      dateTime: "3/2/2101 1am",
      company: "Humana Inc.",
      task: "Moving and packing",
      location: "1185 Arucal Key",
      rate: "$43.23",
      workerId: "5",
      status: "Complete",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
      id: 10,
      dateTime: "12/31/2061 10am",
      company: "Hibernia Corp.",
      task: "Heavy Lifting",
      location: "221 Pidij Grove",
      rate: "$15.43",
      workerId: "9",
      status: "Pending Confirmation",
      details:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    }
  ],
  token: null,
  isCreatingToken: false,
  isGettingToken: false
};

export const gigWorkerReducer = (state = GIGWORKER_INITIAL, action) => {
  switch (action.type) {
    case gigWorkerConstants.UPDATE_WORKER:
      return {
        ...state,
        worker: action.data
      };

    case gigWorkerConstants.UPDATE_GIG:
      return {
        ...state,
        gig: action.data
      };

    case gigWorkerConstants.CREATE_TOKEN_REQUEST:
      return {
        ...state,
        isCreatingToken: true
      };
    case gigWorkerConstants.CREATE_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        isCreatingToken: false
      };
    case gigWorkerConstants.CREATE_TOKEN_FAILURE:
      return {
        ...state,
        token: null,
        isCreatingToken: false
      };

    case gigWorkerConstants.GET_TOKEN_BY_USER_REQUEST:
      return {
        ...state,
        isGettingToken: true
      };
    case gigWorkerConstants.GET_TOKEN_BY_USER_SUCCESS:
      return {
        ...state,
        token: action.payload,
        isGettingToken: false
      };
    case gigWorkerConstants.GET_TOKEN_BY_USER_FAILURE:
      return {
        ...state,
        token: null,
        isGettingToken: false
      };

    default:
      return state;
  }
};
