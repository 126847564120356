import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minHeight: 80,
    padding: 20,
    width: "100%"
  },
  footer: {
    maxWidth: 940,
    margin: "0 auto"
  },
  regLink: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    padding: 10
  },
  copyLink: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    opacity: 0.5,
    padding: 10
  }
});

const Footer = props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.footer}
      >
        <Grid item xs={12} sm={4}>
          <Grid container justify="space-between">
            <Typography>
              <Link to={"/privacy"} className={classes.regLink}>
                Privacy
              </Link>
            </Typography>
            <Typography>
              <Link to={"/terms"} className={classes.regLink}>
                Terms &amp; Conditions
              </Link>
            </Typography>
            <Typography>
              <Link to={"/contact"} className={classes.regLink}>
                Contact
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Typography className={classes.copyLink} component={Link} to="/">
          &copy; BLOCKxStudio, 2018-2019
        </Typography>
      </Grid>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
