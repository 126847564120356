import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import SignUpForm from "./SignUpForm";
import Logo from "../shared/Logo2";
import { invitesActions, organizationsActions } from "../../actions";
import Utils from "./Utils";
import SignUpRequest from "./SignUpRequest";
import Loading from "../shared/Loading";

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  }
});

class SignUpPage extends Component {
  componentDidMount() {
    // Parse URL for code
    const code = Utils.parseLocationByKey(this.props.location, "code");

    // Ask API for the respective Invite with the code
    this.props.getInviteByCode({ code });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.invite &&
      this.props.invite &&
      this.props.invite.organizationId
    ) {
      this.props.getOrganization(this.props.invite.organizationId);
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingInvite) {
      return <Loading text="Retrieving invite..." />;
    }

    if (this.props.isGettingOrganization) {
      return <Loading text="Loading organization..." />;
    }

    return (
      <div style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}>
        <Logo />
        <Paper style={{ padding: 30, marginTop: 30, marginBottom: 30 }}>
          {!this.props.invite && <SignUpRequest />}
          {this.props.invite && <SignUpForm />}
        </Paper>
        <Typography>
          Already have an account?{" "}
          <Link className={classes.link} to={"/login"}>
            Login
          </Link>
        </Typography>
      </div>
    );
  }
}

SignUpPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    location: state.router.location,
    isGettingInvite: state.invites.isGettingInvite,
    invite: state.invites.invite,
    isGettingOrganization: state.organizations.isGettingOrganization
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInviteByCode: invitesActions.getInviteByCode,
      getOrganization: organizationsActions.getOrganization
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignUpPage));
