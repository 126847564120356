import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Grid,
  Hidden,
  Card,
  CardContent,
  CardActions
} from "@material-ui/core";

const styles = {
  card: {
    height: "100%"
  },
  description: {
    fontSize: 18
  },
  title: {
    fontSize: 30
  }
};

class ServiceCard extends React.Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  static defaultProps = {};

  static calculateMdSize(data) {
    if (data.length === 2) {
      return 6;
    }

    if (data.length === 1) {
      return 12;
    }

    return 4;
  }

  render() {
    const { classes, data } = this.props;
    const mdSize = ServiceCard.calculateMdSize(data);

    return (
      <Grid item xs={12} md={mdSize}>
        <Grid
          container
          direction="column"
          justify="space-between"
          component={Card}
          elevation={0}
          className={classes.card}
        >
          <Grid item component={CardContent}>
            <Hidden smDown>
              <img
                alt={data.description}
                src={data.image}
                style={{ width: "100%", marginBottom: 30 }}
              />
            </Hidden>
            <Typography
              variant="h5"
              className={classes.title}
              color="primary"
              gutterBottom
            >
              {data.name}
            </Typography>
            <Typography
              variant="h5"
              className={classes.description}
              gutterBottom
            >
              {data.description}
            </Typography>
          </Grid>
          <Grid item component={CardActions}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={data.path}
              style={{ margin: "0 auto" }}
            >
              {data.button}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ServiceCard);
