import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid
} from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = {
  root: {
    padding: 30,
    margin: "40px 20px",
    textAlign: "center"
  },
  title: {},
  table: {
    margin: "0 auto",
    overflowX: "auto"
  },
  button: {
    margin: 5
  },
  toolbar: {
    marginBottom: 10
  }
};

let id = 0;
function createData(name, phone, status) {
  id += 1;
  return { id, name, phone, status };
}

const data = [
  createData("Eliza Greene", "+12065559214", 0),
  createData("Jonathan Park", "+14255551032", 1),
  createData("Rachel Cowdan", "+13735558865", 0),
  createData("Naya Atwood", "+12065559214", 1),
  createData("Jorge Lowe", "+14255551032", -1),
  createData("Tyler Christensen", "+13735558865", 1)
];

class ListWorkers extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        {this.title()}
        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          className={classes.toolbar}
        >
          <Grid item xs={12} sm={4}>
            <TextField label="Search Workers" fullWidth />
          </Grid>
          <Grid item>
            {this.importButton()}
            {this.addWorkerButton()}
          </Grid>
        </Grid>
        {this.table()}
      </Paper>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4" color="primary" className={classes.title}>
          Gig Worker List
        </Typography>
        <Typography variant="subtitle1">Admin View</Typography>
      </Fragment>
    );
  }

  importButton() {
    const { classes } = this.props;

    return (
      <Button className={classes.button} variant="outlined" color="secondary">
        Import CSV
      </Button>
    );
  }

  addWorkerButton() {
    const { classes } = this.props;

    return (
      <Button
        className={classes.button}
        variant="outlined"
        color="secondary"
        component={Link}
        to="/workflows/gig-worker/2"
      >
        Add Worker
      </Button>
    );
  }

  table() {
    const { classes } = this.props;

    return (
      <div className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(n => (
              <TableRow key={n.id}>
                <TableCell>{n.name}</TableCell>
                <TableCell>{n.phone}</TableCell>
                <TableCell>{this.getLabel(n.status)}</TableCell>
                <TableCell>{n.status === 0 && this.sendSMSButton()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  sendSMSButton() {
    return (
      <Button
        variant="text"
        color="primary"
        component={Link}
        to="/workflows/gig-worker/2"
      >
        Send SMS Verification
      </Button>
    );
  }

  getLabel = status => {
    if (status < 0) {
      return "Rejected";
    }

    if (status > 0) {
      return "Verified";
    }

    return "Pending";
  };
}

export default withStyles(styles)(ListWorkers);
