import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { gigWorkerActions } from "../../../../actions";

const styles = {
  root: {
    padding: 10,
    margin: "40px 0px",
    textAlign: "center"
  },
  title: {},
  table: {
    width: "100%",
    margin: "0 auto",
    overflowX: "auto",
    padding: "10px"
  },
  button: {}
};

class GigListings extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.title()}
        {this.table()}
      </div>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography
          variant="h4"
          color="primary"
          className={classes.title}
        >
          Job Listings
        </Typography>
      </Fragment>
    );
  }

  table() {
    const { classes, gigs } = this.props;

    return (
      <div className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>DateTime</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Task</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>WorkerID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Match</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gigs.map(n => {
              return (
                <TableRow key={n.id}>
                  <TableCell>{n.dateTime}</TableCell>
                  <TableCell>{n.company}</TableCell>
                  <TableCell>{n.task}</TableCell>
                  <TableCell>{n.location}</TableCell>
                  <TableCell>{n.rate}</TableCell>
                  <TableCell>{n.workerId}</TableCell>
                  <TableCell>{n.status}</TableCell>
                  <TableCell>{Math.ceil(Math.random() * 100) + "%"}</TableCell>
                  <TableCell>
                    {this.viewDetailsButton(n)}
                    {n.status !== "Complete" &&
                      n.status !== "Expired" &&
                      this.sendNotificationButton()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  sendNotificationButton() {
    const { classes } = this.props;

    return (
      <Button
        className={classes.button}
        variant="text"
        color="primary"
        component={Link}
        to="/workflows/worker-management/notify-worker"
      >
        Notify
      </Button>
    );
  }

  viewDetailsButton(gigObject) {
    const { classes, updateGig } = this.props;

    return (
      <Button
        className={classes.button}
        variant="text"
        color="primary"
        component={Link}
        to="/workflows/worker-management/gig-details"
        onClick={() => updateGig(gigObject)}
      >
        View Details
      </Button>
    );
  }
}

const mapStateToProps = state => {
  return {
    workers: state.gigWorker.workers,
    gigs: state.gigWorker.gigs
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateGig: gigWorkerActions.updateGig
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GigListings));
