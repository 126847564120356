import { push } from "react-router-redux";
import axios from "axios";
import { BXSAPIURL, cookies } from "../constants";
import { snackbarActions } from "./snackbarActions";
import { invitesActions } from "./invitesActions";

// Types
export const authConstants = {
  LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
  LOGIN_FAILURE: "AUTH_LOGIN_FAILURE",

  TOKEN_LOGIN_REQUEST: "AUTH_TOKEN_LOGIN_REQUEST",
  TOKEN_LOGIN_SUCCESS: "AUTH_TOKEN_LOGIN_SUCCESS",
  TOKEN_LOGIN_FAILURE: "AUTH_TOKEN_LOGIN_FAILURE",

  SIGNUP_REQUEST: "AUTH_SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "AUTH_SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "AUTH_SIGNUP_FAILURE",

  LOGOUT: "AUTH_LOGOUT"
};

// Creators
export const authActions = {
  login,
  tokenLogin,
  logout,
  signup
};

// Implementations
function login({
  email,
  password,
  pushOnSuccess,
  isExternalURL,
  returnToken
} = {}) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "post",
      url: "/login",
      baseURL: BXSAPIURL,
      data: {
        email,
        password
      }
    })
      .then(res => {
        dispatch(success(res.data.payload));
        dispatch(
          snackbarActions.open({
            message: `Welcome ${res.data.payload.name}!`,
            variant: "success"
          })
        );
        cookies.set("token", res.data.token, { path: "/" });
        let goToURL = pushOnSuccess || "/";
        if (returnToken) {
          goToURL = goToURL + `?token=${res.data.token}`;
        }
        if (isExternalURL) {
          window.location.assign(goToURL);
        } else {
          dispatch(push(goToURL));
        }
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Cannot log in: Invalid email or password.`,
            variant: "error"
          })
        );
        cookies.remove("token", { path: "/" });
      });
  };

  function request() {
    return { type: authConstants.LOGIN_REQUEST };
  }
  function success(payload) {
    return { type: authConstants.LOGIN_SUCCESS, payload };
  }
  function failure() {
    return { type: authConstants.LOGIN_FAILURE };
  }
}

function tokenLogin() {
  return dispatch => {
    if (!cookies.get("token")) {
      return;
    }

    dispatch(request());

    axios({
      method: "get",
      url: "/decode",
      baseURL: BXSAPIURL,
      headers: { "x-access-token": cookies.get("token") }
    })
      .then(res => {
        dispatch(success(res.data.payload));
        dispatch(
          snackbarActions.open({
            message: `Welcome back ${res.data.payload.name}!`,
            variant: "success"
          })
        );
      })
      .catch(error => {
        dispatch(failure());
        cookies.remove("token", { path: "/" });
      });
  };

  function request() {
    return { type: authConstants.TOKEN_LOGIN_REQUEST };
  }
  function success(payload) {
    return { type: authConstants.TOKEN_LOGIN_SUCCESS, payload };
  }
  function failure() {
    return { type: authConstants.TOKEN_LOGIN_FAILURE };
  }
}

function logout({ pushOnSuccess } = {}) {
  cookies.remove("token", { path: "/" });
  return dispatch => {
    dispatch(success());
    if (pushOnSuccess) {
      dispatch(push(pushOnSuccess));
    }
    dispatch(
      snackbarActions.open({
        message: "Successfully logged out!",
        variant: "success"
      })
    );
  };

  function success() {
    return { type: authConstants.LOGOUT };
  }
}

function signup({
  name,
  email,
  password,
  internalRole,
  customerRole,
  organization,
  inviteId
}) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "post",
      url: "/users",
      baseURL: BXSAPIURL,
      data: {
        name,
        email,
        password,
        internalRole,
        customerRole,
        organization
      }
    })
      .then(res => {
        if (!res.data.success) {
          throw new Error(res.data.message);
        }
        dispatch(success());
        dispatch(push("/login"));
        dispatch(
          snackbarActions.open({
            message: "Successfully created your account! Log in now",
            variant: "success"
          })
        );
        if (inviteId) {
          dispatch(invitesActions.deleteInvite(inviteId));
        }
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Server error: Unable to create account",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: authConstants.SIGNUP_REQUEST };
  }
  function success() {
    return { type: authConstants.SIGNUP_SUCCESS };
  }
  function failure() {
    return { type: authConstants.SIGNUP_FAILURE };
  }
}
