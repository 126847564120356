import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import RequestForm from "./RequestForm";
import RequestSuccess from "./RequestSuccess";
import Loading from "../shared/Loading";
import { invitesActions } from "../../actions";

const styles = theme => ({});

class SignUpRequest extends Component {
  componentWillUnmount() {
    this.props.clearRequest();
  }

  render() {
    if (this.props.isSendingRequest) {
      return <Loading text="Sending invite request..." />;
    }
    return (
      <Fragment>
        <Typography variant="h4" gutterBottom>
          Join BLOCKxStudio
        </Typography>
        {this.props.request ? <RequestSuccess /> : <RequestForm />}
      </Fragment>
    );
  }
}

SignUpRequest.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    isSendingRequest: state.invites.isSendingRequest,
    request: state.invites.request
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearRequest: invitesActions.clearRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignUpRequest));
