import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import MailchimpSubscribe from "react-mailchimp-subscribe";
// import CustomForm from "react-mailchimp-subscribe";
import { TextField } from "@material-ui/core";

const styles = {};

const CustomForm = ({ status, message, onValidated }) => {
  let email, name;
  const submit = () =>
    email &&
    name &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value
    });

  return (
    <div>
      {status === "sending" && (
        <div style={{ fontFamily: "Roboto", color: "gray" }}>sending...</div>
      )}
      {status === "error" && (
        <div
          style={{ fontFamily: "Roboto", color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ fontFamily: "Roboto", color: "#ad1457" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input
        style={{
          fontFamily: "Roboto",
          fontSize: "1rem",
          border: 0,
          boxSizing: "content-box",
          borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
          padding: "6px 0 7px",
          background: "none",
          marginTop: "16px",
          marginBottom: "8px",
          width: "100%"
        }}
        ref={node => (name = node)}
        type="text"
        placeholder="First, Last Name"
      />
      <br />
      <input
        style={{
          fontFamily: "Roboto",
          fontSize: "1rem",
          border: 0,
          boxSizing: "content-box",
          borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
          padding: "6px 0 7px",
          background: "none",
          marginTop: "16px",
          marginBottom: "8px",
          width: "100%"
        }}
        ref={node => (email = node)}
        type="email"
        placeholder="Email Address"
      />
      <br />
      <button
        style={{
          padding: "8px 16px",
          fontSize: "0.875rem",
          marginTop: "20px",
          color: "#fff",
          backgroundColor: "#ad1457",
          fontFamily: "Roboto",
          fontWeight: 500,
          lineHeight: 1.5,
          borderRadius: "4px",
          letterSpacing: "0.02857em",
          textTransform: "uppercase"
        }}
        onClick={submit}
      >
        Submit
      </button>
    </div>
  );
};

class TrialsContact extends React.Component {
  render() {
    const url =
      "https://blockxstudio.us20.list-manage.com/subscribe/post?u=78075bca6f5dad4dfc599b837&amp;id=f3ee83c30a";
    return (
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Free Trial Signup
        </Typography>
        <Typography variant="h5" color="secondary" gutterBottom>
          Tracking shipments on BLOCKxStudio. Please provide the following info
          to start your account set up process.
        </Typography>
        <Paper style={{ maxWidth: 600, margin: "30px auto 0px", padding: 40 }}>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(TrialsContact);
