import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  linkText: {
    marginTop: 16
  }
});

class RequestSuccess extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography gutterBottom>
          Your request to join BLOCKxStudio has been sent! Once you are
          approved, we will email you an invitation to register your account.
        </Typography>
        <Typography className={classes.linkText} gutterBottom>
          <Link className={classes.link} to={"/"}>
            Return to the BLOCKxStudio Main Site
          </Link>
        </Typography>
      </Fragment>
    );
  }
}

RequestSuccess.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestSuccess));
