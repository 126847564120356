import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../home/Home";
import Nav from "../navbar/Nav";
import Footer from "../footer/Footer";

class FullWidthLayout extends Component {
  render() {
    return (
      <div>
        <Nav />
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default FullWidthLayout;
