import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, TextField } from "@material-ui/core";
import { authActions } from "../../actions";
import Loading from "../shared/Loading";

const styles = theme => ({});

class CustomerSignUp extends Component {
  state = {
    form: {}
  };

  handleFormSubmit = event => {
    event.preventDefault();
    this.props.signup({
      name: this.state.form.name,
      email: this.state.form.email,
      password: this.state.form.password,
      internalRole: "", // default for all non-internal users
      customerRole: this.props.invite.role,
      organization: this.props.invite.organizationId,
      inviteId: this.props.invite._id
    });
  };

  render() {
    if (this.props.isSigningUp) {
      return (
        <div style={{ padding: 20 }}>
          <Loading text="Creating Account" />
        </div>
      );
    }

    return (
      <Fragment>
        {this.props.organization && (
          <Typography variant="subtitle1" gutterBottom>
            Welcome! You're invited to join the {this.props.organization.name}{" "}
            team on BLOCKxStudio.
          </Typography>
        )}
        <form onSubmit={this.handleFormSubmit}>
          <TextField
            required
            key="email"
            id="email"
            label="Email"
            autoComplete="email"
            type="text"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  email: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
            helperText="You will use this to log into your account."
          />
          <TextField
            required
            key="name"
            id="name"
            label="Full Name"
            autoComplete="name"
            type="text"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  name: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            required
            key="password"
            id="password"
            label="Password"
            autoComplete="password"
            type="password"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  password: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            color="primary"
            style={{ marginTop: 40, marginBottom: 20 }}
            variant="contained"
          >
            Join Team
          </Button>
        </form>
      </Fragment>
    );
  }
}

CustomerSignUp.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    isSigningUp: state.auth.isSigningUp,
    invite: state.invites.invite,
    organization: state.organizations.organization
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signup: authActions.signup
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomerSignUp));
