import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Header from "./Header";
import RequestDemo from "./RequestDemo";
import Services from "./Services";

const styles = {};

class Home extends React.Component {
  static protoTypes = {};

  static defaultProps = {};

  render() {
    return (
      <div>
        <Header />
        <Services />
        <RequestDemo />
      </div>
    );
  }
}

export default withStyles(styles)(Home);
