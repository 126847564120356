import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, Grid } from "@material-ui/core";
import backgroundImage from "../../images/footer_contact.png";

const styles = theme => ({
  text: {
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    marginBottom: 40
  },
  button: {
    color: theme.palette.secondary.light
  },
  buttonText: {
    color: theme.palette.secondary.dark
  }
});

class RequestDemo extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        alignItems="center"
        direction="column"
        justify="center"
        component={Paper}
        elevation={0}
        style={{
          padding: 20,
          width: "100%",
          height: 300,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
        <Grid
          item
          component={Typography}
          variant="h5"
          align="center"
          className={classes.text}
        >
          Learn more about BLOCKxStudio. Talk to our experts.
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.button}
            component={Link}
            to={"/contact"}
          >
            <span className={classes.buttonText}>Request Demo</span>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

RequestDemo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RequestDemo);
