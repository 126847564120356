// Types
export const gigWorkerConstants = {
  UPDATE_WORKER: "UPDATE_WORKER",
  UPDATE_GIG: "UPDATE_GIG"
};

// Creators
export const gigWorkerActions = {
  updateWorker,
  updateGig
};

// Implementations
function updateWorker(values) {
  return dispatch => {
    dispatch(update(values));
  };

  function update(data) {
    return { type: gigWorkerConstants.UPDATE_WORKER, data };
  }
}

function updateGig(values) {
  return dispatch => {
    dispatch(update(values));
  };

  function update(data) {
    return { type: gigWorkerConstants.UPDATE_GIG, data };
  }
}
