import { snackbarConstants } from "../actions";

const SNACKBAR_INITIAL = {
  message: "",
  variant: ""
};

export const snackbarReducer = (state = SNACKBAR_INITIAL, action) => {
  switch (action.type) {
    case snackbarConstants.OPEN_SNACKBAR:
      return {
        ...state,
        message: action.message,
        variant: action.variant
      };
    case snackbarConstants.CLEAR_SNACKBAR:
      return {
        ...state,
        message: ""
      };

    default:
      return state;
  }
};
