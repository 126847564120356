import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { getRoleById } from "../../constants/Roles";
import InternalSignUp from "./InternalSignUp";
import CustomerSignUp from "./CustomerSignUp";
import NewCustomerSignUp from "./NewCustomerSignUp";

const styles = theme => ({});

class SignUpForm extends Component {
  render() {
    const role = getRoleById(this.props.invite.role);

    if (role.isInternal) {
      return <InternalSignUp />;
    }

    if (role.id === "customer-owner") {
      return <NewCustomerSignUp />;
    }

    return <CustomerSignUp />;
  }
}

const mapStateToProps = state => {
  return {
    invite: state.invites.invite,
    organization: state.organizations.organization
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignUpForm));
