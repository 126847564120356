import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IconNavMobile from "../../images/icon_nav_mobile.png";
import {
  Typography,
  Hidden,
  Button,
  IconButton,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Divider,
  SwipeableDrawer
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { authActions } from "../../actions";
import { cookies } from "../../constants";

const styles = theme => ({
  link: {
    textDecoration: "none",
    color: "grey"
  },
  logo: {
    flex: 1,
    textDecoration: "none",
    fontWeight: 600
  },
  fullMenu: {
    marginLeft: "auto"
  },
  mobileMenu: {
    marginLeft: "auto"
  },
  logoStudio: {
    color: theme.palette.primary.main
  },
  logoLink: {
    textDecoration: "none"
  },
  logoBLOCK: {
    color: "gray"
  },
  hiddenTab: {
    display: "none"
  },
  avatar: {
    marginLeft: 10
  },
  authBarStrip: {
    textAlign: "right",
    padding: "4px 16px",
    color: "#222",
    borderTop: "solid 1px #f2f2f2"
  }
});

class Nav extends React.Component {
  state = {
    accountMenu: null,
    anchorEl: null,
    value: false,
    menuOpen: false
  };

  componentDidMount() {
    const { pathname } = this.props;

    // When the first tab does not match the current pathname...
    if (this.state.value !== pathname) {
      this.updateTabSelection();
    }
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props;

    // When the pathname changes...
    if (prevProps.pathname !== pathname) {
      this.updateTabSelection();
    }
  }

  handleCloseAccount = () => {
    this.setState({ accountMenu: null });
  };

  handleLogoutAccount = () => {
    this.props.logout({ pushOnSuccess: "/" });
    this.setState({ accountMenu: null, anchorEl: null });
  };

  handleClickMobile = e => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  toggleMenu = e => {
    this.setState({
      anchorEl: e.currentTarget || null,
      menuOpen: !this.state.menuOpen
    });
  };

  handleCloseMobile = () => {
    this.setState({ anchorEl: null });
  };

  renderAuthFull() {
    return (
      <Button variant="text" color="secondary" component={Link} to="/login">
        Log In
      </Button>
    );
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  toggleMenuMdUp = e => {
    this.setState({
      accountMenu: e.currentTarget
    });
  };

  updateTabSelection() {
    const { pathname } = this.props;

    const validValues = ["/templates", "/services", "/contact"];

    if (validValues.includes(pathname)) {
      // Select the tab matching the current pathname
      this.setState({ value: pathname });
    } else {
      // Otherwise, select none of the tabs
      this.setState({ value: false });
    }
  }

  renderFullMenu() {
    const { value } = this.state;

    return (
      <Fragment>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={this.handleChange}
          style={{
            marginBottom: "0px",
            paddingBottom: "0px"
          }}
        >
          <Tab
            label="Templates"
            component={Link}
            to="/templates"
            value={"/templates"}
          />
          <Tab
            label="Services"
            component={Link}
            to="/services"
            value={"/services"}
          />
          <Tab
            label="Contact"
            component={Link}
            to="/contact"
            value={"/contact"}
          />
        </Tabs>
      </Fragment>
    );
  }

  handleLogout = e => {
    this.props.logout({ pushOnSuccess: "/" });

    this.setState({
      anchorEl: null,
      menuOpen: false
    });
  };

  renderWalletPreview = () => {
    const { user } = this.props;
    return (
      <Fragment>
        <ListItem>
          <ListItemText secondary={`Logged in as ${user.name}`} />
        </ListItem>
      </Fragment>
    );
  };

  renderLogo() {
    const { classes } = this.props;

    return (
      <Typography
        variant="h4"
        className={classes.logo}
        component={Link}
        to={"/"}
        onClick={e => this.handleChange(e, false)}
      >
        <span className={classes.logoBLOCK}>BLOCK</span>
        <span className={classes.logoStudio}>xStudio</span>
      </Typography>
    );
  }

  renderLogoMobile() {
    return (
      <img
        src={IconNavMobile}
        width="30px"
        height="auto"
        alt="BLOCKxStudio Mobile Logo"
      />
    );
  }

  renderAuthFragmentSmDown = () => {
    return (
      <Fragment>
        {this.props.user.internalRole && (
          <ListItem component={Link} onClick={this.toggleMenu} to="/admin">
            <ListItemText primary="Admin" />
          </ListItem>
        )}
        <ListItem
          component={Button}
          onClick={this.toggleMenu}
          href={`https://app.blockxstudio.com/?token=${cookies.get("token")}`}
        >
          <ListItemText primary="My Studio" />
        </ListItem>
        <ListItem component={Button} onClick={this.handleLogout}>
          <ListItemText primary="Log out" />
        </ListItem>
      </Fragment>
    );
  };

  renderGuestFragmentSmDown = () => {
    return (
      <Fragment>
        <ListItem component={Link} onClick={this.toggleMenu} to="/login">
          <ListItemText primary="Log in" />
        </ListItem>
      </Fragment>
    );
  };

  renderGuestFragmentMdUp = () => {
    const { classes } = this.props;

    return (
      <Link to="/login" className={classes.link} onClick={this.toggleMenu}>
        <Button>Log in</Button>
      </Link>
    );
  };

  renderAuthFragmentMdUp = () => {
    return (
      <Fragment>
        {this.props.user.internalRole && (
          <Button component={Link} to="/admin">
            Admin
          </Button>
        )}
        <Button
          href={`https://app.blockxstudio.com/?token=${cookies.get("token")}`}
        >
          My Studio
        </Button>
        <Button onClick={this.handleLogoutAccount}>Logout</Button>
      </Fragment>
    );
  };

  renderDesktopMenu = () => {
    return (
      <div
        style={{
          padding: "0px 30px",
          display: "flex",
          flexDirection: "row no-wrap",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          {this.renderLogo()}
          {this.renderFullMenu()}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          {!this.props.user
            ? this.renderGuestFragmentMdUp()
            : this.renderAuthFragmentMdUp()}
        </div>
      </div>
    );
  };

  renderMobileMenu = () => {
    const { classes, user } = this.props;
    const { menuOpen } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px"
        }}
      >
        {this.renderLogo()}
        <MenuIcon onClick={this.toggleMenu} />
        <SwipeableDrawer
          anchor="top"
          open={menuOpen}
          onClose={this.toggleMenu}
          onOpen={this.toggleMenu}
        >
          <List>
            {/* nav header */}
            <ListItem>
              {this.renderLogo()}
              <IconButton onClick={this.toggleMenu}>
                <CloseIcon />
              </IconButton>
            </ListItem>
            {/* wallet preview */}
            {!!user && <ListItemText secondary={`Logged in as ${user.name}`} />}
            {/* static links */}
            <ListItem>
              <Link to="/" className={classes.link} onClick={this.toggleMenu}>
                <ListItemText primary="Home" />
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="/templates"
                className={classes.link}
                onClick={this.toggleMenu}
              >
                <ListItemText primary="Templates" />
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="/services"
                className={classes.link}
                onClick={this.toggleMenu}
              >
                <ListItemText primary="Services" />
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="/contact"
                className={classes.link}
                onClick={this.toggleMenu}
              >
                <ListItemText primary="Contact" />
              </Link>
            </ListItem>
            <Divider />
            {/* dynamic fragments */}
            {!user
              ? this.renderGuestFragmentSmDown()
              : this.renderAuthFragmentSmDown()}
          </List>
        </SwipeableDrawer>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Hidden mdUp>{this.renderMobileMenu()}</Hidden>
        <Hidden smDown>{this.renderDesktopMenu()}</Hidden>

        {!!this.props.user && (
          <Hidden smDown>
            <div className={this.props.classes.authBarStrip}>
              <Typography variant="caption" color="inherit">
                Signed in as {this.props.user.name} ({this.props.user.email})
              </Typography>
            </div>
          </Hidden>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isLoggingIn: state.auth.isLoggingIn,
  pathname: state.router.location.pathname
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout: authActions.logout
    },
    dispatch
  );

Nav.protoTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Nav));
