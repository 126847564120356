import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import qs from "qs";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

const styles = {
  root: {
    textAlign: "center",
    marginTop: 50
  },
  link: {
    textDecoration: "none"
  }
};

class AuthGate extends Component {
  protoTypes = {
    classes: PropTypes.object.isRequired,
    redirect: PropTypes.bool,
    path: PropTypes.string,
    text: PropTypes.string
  };

  static defaultProps = {
    redirect: false,
    path: "",
    text: "You must log in to proceed."
  };

  render() {
    const { returnBack, currentPath, path, text, classes } = this.props;

    const ref = qs.stringify({ ref: returnBack ? currentPath : path });

    return (
      <div className={classes.root}>
        <Typography variant="h5" color="secondary" gutterBottom>
          {text}
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          component={Link}
          to={`/login?${ref}`}
          className={classes.link}
        >
          Go to Login
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentPath: state.router.location.pathname
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AuthGate));
