import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";

const styles = {};

class ContactForm extends React.Component {
  render() {
    return (
      <form method="POST" action="https://formspree.io/trial@blockxstudio.com">
        <TextField
          key="name"
          id="name"
          label="Name"
          autoComplete="name"
          type="name"
          name="name"
          fullWidth
          margin="normal"
        />
        <TextField
          key="email"
          id="email"
          label="Email"
          autoComplete="email"
          type="email"
          name="_replyto"
          fullWidth
          margin="normal"
        />
        <TextField
          key="subject"
          id="subject"
          label="Industry"
          autoComplete="subject"
          type="subject"
          name="_subject"
          fullWidth
          margin="normal"
        />
        <TextField
          key="message"
          id="message"
          label="Describe Your Role"
          autoComplete="message"
          type="text"
          name="message"
          multiline
          rows={6}
          fullWidth
          margin="normal"
        />
        <TextField key="_format" type="hidden" name="_format" value="plain" />
        {/* "honeypot" field to avoid spam by fooling scrapers. If a value is provided, the submission will be silently ignored */}
        <TextField
          key="_gotcha"
          type="text"
          name="_gotcha"
          style={{ display: "none" }}
        />
        <Button
          type="submit"
          color="primary"
          style={{ marginTop: 20 }}
          variant="contained"
        >
          Submit
        </Button>
      </form>
    );
  }
}

export default withStyles(styles)(ContactForm);
