import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, TextField } from "@material-ui/core";
import { authActions, organizationsActions } from "../../actions";
import Loading from "../shared/Loading";

const styles = theme => ({});

class NewCustomerSignup extends Component {
  state = {
    form: {}
  };

  handleFormSubmit = event => {
    event.preventDefault();
    this.props.createOrganization({ name: this.state.form.organization });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isCreatingOrganization && this.props.organization) {
      this.props.signup({
        name: this.state.form.name,
        email: this.state.form.email,
        password: this.state.form.password,
        internalRole: "", // default for all non-internal users
        customerRole: this.props.invite.role,
        organization: this.props.organization._id,
        inviteId: this.props.invite._id
      });
    }
  }

  render() {
    if (this.props.isSigningUp) {
      return (
        <div style={{ padding: 20 }}>
          <Loading text="Creating Account" />
        </div>
      );
    }

    return (
      <Fragment>
        <Typography variant="subtitle1" gutterBottom>
          Welcome! You're invited to create your organization on BLOCKxStudio.
        </Typography>
        <form onSubmit={this.handleFormSubmit}>
          <TextField
            required
            key="email"
            id="email"
            label="Email"
            autoComplete="email"
            type="text"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  email: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
            helperText="You will use this to log into your account."
          />
          <TextField
            required
            key="name"
            id="name"
            label="Full Name"
            autoComplete="name"
            type="text"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  name: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            required
            key="password"
            id="password"
            label="Password"
            autoComplete="password"
            type="password"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  password: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            required
            key="organization"
            id="organization"
            label="Organization Name"
            autoComplete="organization"
            type="text"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  organization: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            color="primary"
            style={{ marginTop: 40, marginBottom: 20 }}
            variant="contained"
          >
            Join BLOCKxStudio
          </Button>
        </form>
      </Fragment>
    );
  }
}

NewCustomerSignup.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    isSigningUp: state.auth.isSigningUp,
    invite: state.invites.invite,
    organization: state.organizations.organization,
    isCreatingOrganization: state.organizations.isCreatingOrganization
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signup: authActions.signup,
      createOrganization: organizationsActions.createOrganization
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewCustomerSignup));
