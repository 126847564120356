import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, Typography, CardContent, Grid, Paper } from "@material-ui/core";
import ContactForm from "../contact/ContactForm.js";

const styles = {
  card: {
    margin: 10
  },
  title: {
    marginBottom: 16,
    fontSize: 14
  }
};

const services = [
  {
    name: "Enterprise Application Integration",
    type: "Engineering Services",
    details:
      "Integrate connectivity between a distributed application and Salesforce, Office 365, Google Apps or other popular application, analytics, billing, marketing automation platforms and services."
  },

  {
    name: "Enterprise Messaging Integration",
    type: "Engineering Services",
    details:
      "Accelerate blockchain communication; enabling smart contracts driven from your in-house email and text. Leverage investments in communication applications while building workflow automation on blockchain."
  },

  {
    name: "ERC20 Wallets and Payments Integration",
    type: "Engineering Services",
    details:
      "Automate integration between your enterprise applications and any of the popular ERC20 wallets and payment services. This simplifies wallet transactions for your enterprise customers."
  }
];

class ListServices extends React.Component {
  renderList() {
    const { classes } = this.props;

    if (!services) {
      return <Typography variant="h6">No Templates Available</Typography>;
    }

    return (
      <Grid container wrap="wrap">
        {services.map((service, key) => (
          <Grid className={classes.card} key={key} component={Card} item md>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                SERVICE
              </Typography>
              <Typography gutterBottom variant="h5" component="h2">
                {service.name}
              </Typography>
              <Typography component="p">{service.details}</Typography>
            </CardContent>
          </Grid>
        ))}
      </Grid>
    );
  }

  render() {
    return (
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Engineering Services
        </Typography>
        <Typography variant="h5" color="secondary" gutterBottom>
          Solve complex blockchain distributed application design, engineering,
          and integration challenges with dedicated on-premise services. Sample
          services below.
        </Typography>
        {this.renderList()}
        <div style={{ marginTop: 80 }}>
          <Typography variant="h3" color="secondary" gutterBottom>
            Get a Quote
          </Typography>
          <Typography variant="h5" color="secondary" gutterBottom>
            Contact us for a quote on our engineering services. We're also
            available to discuss your blockchain questions at
            info@blockxstudio.com or 415-294-0983.
          </Typography>
          <Paper
            style={{ maxWidth: 600, margin: "30px auto 0px", padding: 40 }}
          >
            <ContactForm />
          </Paper>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ListServices);
