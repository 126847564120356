import React, { Component, Fragment } from "react";
import { ConnectedRouter } from "react-router-redux";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "./Store";
import Theme from "./Theme";
import Loading from "./components/shared/Loading";
import FullWidthLayout from "./components/layouts/FullWidthLayout";
import HeadlessLayout from "./components/layouts/HeadlessLayout";
import HeaderLayout from "./components/layouts/HeaderLayout";
import AuthLayout from "./components/layouts/AuthLayout";
import { authActions, web3Actions } from "./actions";
import Snackbar from "./components/snackbar/Snackbar";
import ReactGA from "react-ga";

ReactGA.initialize("UA-116756812-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  componentDidMount() {
    this.props.tokenLogin();
    this.props.initWeb3();
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <Theme>{this.root()}</Theme>
      </ConnectedRouter>
    );
  }

  root() {
    if (this.props.isLoggingIn) {
      return <Loading text="Logging In..." />;
    }

    return (
      <Fragment>
        <Snackbar />
        <Switch>
          <Route exact path="/" component={FullWidthLayout} />
          <Route path="/login" component={AuthLayout} />
          <Route path="/signup" component={AuthLayout} />
          <Route path="/forgotpassword" component={AuthLayout} />
          <Route path="/workflows" component={HeadlessLayout} />
          <Route component={HeaderLayout} />
        </Switch>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoggingIn: state.auth.isLoggingIn
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      tokenLogin: authActions.tokenLogin,
      initWeb3: web3Actions.initWeb3
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
