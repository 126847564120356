import { web3Constants } from "../actions";

const WEB3_INITIAL = {
  instance: null
};

export const web3Reducer = (state = WEB3_INITIAL, action) => {
  switch (action.type) {
    case web3Constants.INIT_WEB3_SUCCESS:
      return {
        ...state,
        instance: action.web3Instance
      };

    case web3Constants.INIT_WEB3_FAILURE:
      return {
        ...state
      };

    default:
      return state;
  }
};
