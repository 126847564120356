import React, { Component } from "react";
import { Typography } from "@material-ui/core";

class DeadEnd extends Component {
  render() {
    return (
      // <div style={{ minHeight: 500, padding: 30 }}>
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <Typography variant="h2" color="secondary" gutterBottom>
          404
        </Typography>
        <Typography variant="h4" gutterBottom>
          We couldn’t find this page.
        </Typography>
      </div>
    );
  }
}

export default DeadEnd;
