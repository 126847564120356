import React, { Component } from "react";
import { Typography, CircularProgress } from "@material-ui/core";

class Loading extends Component {
  render() {
    const text = this.props.text || "";
    return (
      <div style={{ textAlign: "center", margin: "40px 0px" }}>
        <CircularProgress />
        <Typography variant={this.props.variant || "h4"}>{text}</Typography>
      </div>
    );
  }
}

export default Loading;
