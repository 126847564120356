import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, CircularProgress } from "@material-ui/core";
import { workflowsActions } from "../../actions";

const styles = {
  root: {
    textAlign: "left",
    maxWidth: 1200,
    margin: "0 auto"
  }
};

class ViewWorkflow extends Component {
  componentDidMount() {
    this.props.getWorkflow(this.props.match.params.id);
  }

  render() {
    const { workflow, isGettingWorkflow, classes } = this.props;

    if (isGettingWorkflow) {
      return (
        <div>
          <CircularProgress />
          <Typography variant="h6">Loading Workflow</Typography>
        </div>
      );
    }

    if (!workflow) {
      return <Typography variant="h6">Workflow Not Found</Typography>;
    }

    return (
      <Paper className={classes.root} elevation={0}>
        <Typography variant="subtitle1" color="secondary" gutterBottom>
          WORKFLOW
        </Typography>
        <Typography variant="h3" color="primary" gutterBottom>
          {workflow.name}
        </Typography>
        <Typography variant="h4" gutterBottom>
          No data available for this workflow.
        </Typography>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    workflow: state.workflows.workflow,
    isGettingWorkflow: state.workflows.isGettingWorkflow
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWorkflow: workflowsActions.getWorkflow
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ViewWorkflow));
