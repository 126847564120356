import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  TextField
} from "@material-ui/core";
import profile_img from "../../../../images/profile_default.png";

const styles = {
  root: {
    // padding: 30,
    margin: "20px 0px",
    textAlign: "center"
  },
  title: {},
  grid: {
    flexGrow: 1,
    margin: "20px auto"
  },
  sectionTitle: {
    margin: "10px auto"
  },
  submitButton: {
    marginTop: 30
  }
};

class Profile extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h6" gutterBottom className={classes.sectionTitle}>
          Basic Information
        </Typography>
        {this.basicInfo()}
        <Typography variant="h6" className={classes.sectionTitle}>
          Interests and Preferences
        </Typography>
        <Grid container spacing={8} className={classes.grid}>
          <Grid item xs={12} sm={4}>
            <TextField fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField fullWidth />
          </Grid>
        </Grid>
        {this.submitButton()}
      </div>
    );
  }

  submitButton() {
    const { classes } = this.props;

    return (
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
      >
        Save My Profile
      </Button>
    );
  }

  basicInfo() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.grid} spacing={16}>
        <Grid item xs={12} sm={4}>
          <img
            src={profile_img}
            alt="Gig Worker Worfklow Profile"
            style={{ width: "100%" }}
          />
          <Button
            color="secondary"
            variant="outlined"
            style={{ marginTop: 10 }}
          >
            Upload Picture
          </Button>
        </Grid>
        <Grid item xs={12} sm={8}>
          <List component="nav">
            <ListItem>
              <ListItemText primary="Kathryn Brusewitz" secondary="Name" />
              <ListItemSecondaryAction>
                <Button color="secondary" variant="outlined">
                  Edit
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="kathryn.brusewitz@gmail.com"
                secondary="Email"
              />
              <ListItemSecondaryAction>
                <Button color="secondary" variant="outlined">
                  Edit
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="+14257853589" secondary="Phone Number" />
              <ListItemSecondaryAction>
                <Button color="secondary" variant="outlined">
                  Edit
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Profile);
