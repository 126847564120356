import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { unregister } from "./registerServiceWorker";
import Store from "./Store";
import App from "./App";

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// https://github.com/facebook/create-react-app/issues/1910
// Once service worker is registered on client's browser they would never fetch the latest copy from server. We must de-register it from the user's browser
unregister();
