import React, { Component, Fragment } from "react";
import chance from "chance";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = {
  root: {
    padding: 30,
    margin: "40px 20px",
    textAlign: "center"
  },
  title: {},
  table: {
    // maxWidth: 400,
    margin: "0 auto",
    overflowX: "auto",
    padding: "20px"
  },
  button: {}
};

let id = 0;
function createData(company, task, worker, match) {
  id += 1;
  return { id, company, task, worker, match };
}

const data = [
  createData("Uber", "taskA", "workerA", 0.82),
  createData("Lyft", "taskB", "workerB", 0.47),
  createData("Rover", "taskC", "workerC", 0.84),
  createData("Casa Latina", "taskD", "workerD", 0.98),
  createData("Dolly", "taskD", "workerF", 0.36)
];

class GigListings extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        {this.title()}
        {this.table()}
      </Paper>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4" color="primary" className={classes.title}>
          Gig Listings
        </Typography>
        <Typography variant="subtitle1">System/Admin View</Typography>
      </Fragment>
    );
  }

  table() {
    const { classes } = this.props;
    const chanceInstance = chance();

    return (
      <div className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date / Time</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Task</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(n => {
              return (
                <TableRow key={n.id}>
                  {/* <TableCell>{n.company}</TableCell>
                  <TableCell>{n.task}</TableCell>
                  <TableCell>{n.worker}</TableCell>
                  <TableCell>{n.match * 100}%</TableCell>
                  <TableCell>{this.button()}</TableCell> */}

                  <TableCell>
                    {chanceInstance.date({ string: "true" })}
                  </TableCell>
                  <TableCell>{chanceInstance.company()}</TableCell>
                  <TableCell>Task description</TableCell>
                  <TableCell>{chanceInstance.address()}</TableCell>
                  <TableCell>{chance.dollar({ max: 100 })}</TableCell>
                  <TableCell>
                    <Button>Details</Button>
                    <Button type="primary">Take Gig</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  button() {
    const { classes } = this.props;

    return (
      <Button
        className={classes.button}
        variant="text"
        color="primary"
        component={Link}
        to="/workflows/gig-worker/5"
      >
        Send SMS Notification
      </Button>
    );
  }
}

export default withStyles(styles)(GigListings);
