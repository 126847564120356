import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import InternalInvites from "./InternalInvites";
import RequestsSection from "./RequestsSection";
import UserList from "../userbase/UserList";

const styles = {
  paper: {
    marginTop: 30,
    marginBottom: 30,
    overflowX: "scroll"
  }
};

class Admin extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Typography variant="h3" color="primary" gutterBottom>
          Admin Dashboard
        </Typography>
        <Paper className={classes.paper} elevation={0}>
          <UserList />
        </Paper>
        <Paper className={classes.paper} elevation={0}>
          <InternalInvites />
        </Paper>
        <Paper className={classes.paper} elevation={0}>
          <RequestsSection />
        </Paper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Admin));
