import React from "react";
import { Typography } from "@material-ui/core";
import ListPacks from "./ListPacks";

class AllPacks extends React.Component {
  render() {
    return (
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Packs
        </Typography>
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <ListPacks />
        </div>
      </div>
    );
  }
}

export default AllPacks;
