import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "@material-ui/core";

class TemplateTabs extends React.Component {
  state = {
    value: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.pathname.includes("demo") ? 1 : 0
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { template } = this.props;
    const { value } = this.state;

    return (
      <Tabs
        value={value}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="primary"
        style={{ marginBottom: 20 }}
      >
        {/* <Tab
          value={0}
          label="PURCHASE"
          component={Link}
          to={`/templates/templates/${template._id}`}
        />
        <Tab
          value={1}
          label="DEMO"
          component={Link}
          to={`/templates/templates/${template._id}/demo`}
        /> */}
      </Tabs>
    );
  }
}

const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname
  };
};

export default connect(mapStateToProps)(TemplateTabs);
