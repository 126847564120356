import axios from "axios";
import { BXSAPIURL } from "../constants";
import { snackbarActions } from "./snackbarActions";

// Types
export const invitesConstants = {
  GET_INVITES_REQUEST: "GET_INVITES_REQUEST",
  GET_INVITES_SUCCESS: "GET_INVITES_SUCCESS",
  GET_INVITES_FAILURE: "GET_INVITES_FAILURE",

  GET_INVITE_REQUEST: "GET_INVITE_REQUEST",
  GET_INVITE_SUCCESS: "GET_INVITE_SUCCESS",
  GET_INVITE_FAILURE: "GET_INVITE_FAILURE",

  DELETE_INVITE_REQUEST: "DELETE_INVITE_REQUEST",
  DELETE_INVITE_SUCCESS: "DELETE_INVITE_SUCCESS",
  DELETE_INVITE_FAILURE: "DELETE_INVITE_FAILURE",

  SEND_INVITE_REQUEST: "SEND_INVITE_REQUEST",
  SEND_INVITE_SUCCESS: "SEND_INVITE_SUCCESS",
  SEND_INVITE_FAILURE: "SEND_INVITE_FAILURE",

  GET_REQUESTS_REQUEST: "GET_REQUESTS_REQUEST",
  GET_REQUESTS_SUCCESS: "GET_REQUESTS_SUCCESS",
  GET_REQUESTS_FAILURE: "GET_REQUESTS_FAILURE",

  GET_REQUEST_REQUEST: "GET_REQUEST_REQUEST",
  GET_REQUEST_SUCCESS: "GET_REQUEST_SUCCESS",
  GET_REQUEST_FAILURE: "GET_REQUEST_FAILURE",

  DELETE_REQUEST_REQUEST: "DELETE_REQUEST_REQUEST",
  DELETE_REQUEST_SUCCESS: "DELETE_REQUEST_SUCCESS",
  DELETE_REQUEST_FAILURE: "DELETE_REQUEST_FAILURE",

  SEND_REQUEST_REQUEST: "SEND_REQUEST_REQUEST",
  SEND_REQUEST_SUCCESS: "SEND_REQUEST_SUCCESS",
  SEND_REQUEST_FAILURE: "SEND_REQUEST_FAILURE",

  CLEAR_REQUEST: "CLEAR_REQUEST"
};

// Creators
export const invitesActions = {
  getInvite,
  getInvites,
  deleteInvite,
  sendInvite,
  getInviteByCode,
  getRequest,
  getRequests,
  deleteRequest,
  sendRequest,
  clearRequest
};

// Implementations
function getInvites() {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: "/invites",
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: invitesConstants.GET_INVITES_REQUEST };
  }
  function success(payload) {
    return { type: invitesConstants.GET_INVITES_SUCCESS, payload };
  }
  function failure() {
    return { type: invitesConstants.GET_INVITES_FAILURE };
  }
}

function getInvite(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/invites/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: invitesConstants.GET_INVITE_REQUEST };
  }
  function success(payload) {
    return { type: invitesConstants.GET_INVITE_SUCCESS, payload };
  }
  function failure() {
    return { type: invitesConstants.GET_INVITE_FAILURE };
  }
}

function deleteInvite(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "delete",
      url: `/invites/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: invitesConstants.DELETE_INVITE_REQUEST };
  }
  function success(payload) {
    return { type: invitesConstants.DELETE_INVITE_SUCCESS, payload };
  }
  function failure() {
    return { type: invitesConstants.DELETE_INVITE_FAILURE };
  }
}

function sendInvite(values) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "post",
      url: "/invites",
      baseURL: BXSAPIURL,
      data: values
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: invitesConstants.SEND_INVITE_REQUEST };
  }
  function success(payload) {
    return { type: invitesConstants.SEND_INVITE_SUCCESS, payload };
  }
  function failure() {
    return { type: invitesConstants.SEND_INVITE_FAILURE };
  }
}

function getInviteByCode({ code = "" } = {}) {
  return dispatch => {
    dispatch(request());

    if (!code) {
      dispatch(failure());
      return;
    }

    axios({
      method: "get",
      url: `/invites?code=${code}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          // Need to convert the payload array into a single object
          if (!res.data.payload || res.data.payload.length < 1) {
            dispatch(success(null));
          } else {
            dispatch(success(res.data.payload[0]));
          }
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: invitesConstants.GET_INVITE_REQUEST };
  }
  function success(payload) {
    return { type: invitesConstants.GET_INVITE_SUCCESS, payload };
  }
  function failure() {
    return { type: invitesConstants.GET_INVITE_FAILURE };
  }
}

function getRequests() {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: "/inviterequests",
      baseURL: BXSAPIURL
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to load invite requests.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: invitesConstants.GET_REQUESTS_REQUEST };
  }
  function success(payload) {
    return { type: invitesConstants.GET_REQUESTS_SUCCESS, payload };
  }
  function failure() {
    return { type: invitesConstants.GET_REQUESTS_FAILURE };
  }
}

function getRequest(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/inviterequests/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to load invite request.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: invitesConstants.GET_REQUEST_REQUEST };
  }
  function success(payload) {
    return { type: invitesConstants.GET_REQUEST_SUCCESS, payload };
  }
  function failure() {
    return { type: invitesConstants.GET_REQUEST_FAILURE };
  }
}

function deleteRequest(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "delete",
      url: `/inviterequests/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        dispatch(success(res.data.payload));
        dispatch(
          snackbarActions.open({
            message: "Invite request has been deleted.",
            variant: "success"
          })
        );
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to delete invite request.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: invitesConstants.DELETE_REQUEST_REQUEST };
  }
  function success(payload) {
    return { type: invitesConstants.DELETE_REQUEST_SUCCESS, payload };
  }
  function failure() {
    return { type: invitesConstants.DELETE_REQUEST_FAILURE };
  }
}

function sendRequest(values) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "post",
      url: "/inviterequests",
      baseURL: BXSAPIURL,
      data: values
    })
      .then(res => {
        dispatch(success(res.data.payload));
        dispatch(
          snackbarActions.open({
            message: "Invite request has been sent.",
            variant: "success"
          })
        );
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to send invite request.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: invitesConstants.SEND_REQUEST_REQUEST };
  }
  function success(payload) {
    return { type: invitesConstants.SEND_REQUEST_SUCCESS, payload };
  }
  function failure() {
    return { type: invitesConstants.SEND_REQUEST_FAILURE };
  }
}

function clearRequest() {
  return dispatch => {
    dispatch({ type: invitesConstants.CLEAR_REQUEST });
  };
}
