import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DeadEnd from "../shared/DeadEnd";
import LogInPage from "../auth/LogInPage";
import SignUpPage from "../auth/SignUpPage";
import ForgotPasswordPage from "../auth/ForgotPasswordPage";

class AuthLayout extends Component {
  render() {
    return (
      <div style={{ padding: 40, minHeight: "calc(100vh - 80px)" }}>
        <Switch>
          <Route path="/login" component={LogInPage} />
          <Route path="/signup" component={SignUpPage} />
          <Route path="/forgotpassword" component={ForgotPasswordPage} />
          <Route component={DeadEnd} />
        </Switch>
      </div>
    );
  }
}

export default AuthLayout;
