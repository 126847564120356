import React, { Component, Fragment } from "react";
import chance from "chance";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Hidden,
  Card,
  CardActions,
  CardContent,
  CardMedia
} from "@material-ui/core";
import { Link } from "react-router-dom";
import location_img from "../../../../images/gig-details-location.png";

const styles = {
  root: {
    padding: 30,
    margin: "40px 20px",
    textAlign: "center"
  },
  title: {},
  table: {
    margin: "0 auto",
    overflowX: "auto",
    padding: "10px"
  },
  button: {},
  card: {
    maxWidth: "100vw",
    marginBottom: 20
  },
  media: {
    height: 140
  }
};

const chanceInstance = chance();

let id = 0;
function createData() {
  id += 1;

  const tasks = [
    "Mounting and installation",
    "Moving and packing",
    "Heavy Lifting",
    "General Handyman",
    "Dog sitting"
  ];

  return {
    id,
    dateTime: `${chanceInstance.date({
      string: true
    })} ${chanceInstance.hour()}${chanceInstance.ampm()}`,
    company: chanceInstance.company(),
    task: tasks[Math.floor(Math.random() * tasks.length)],
    location: chanceInstance.address(),
    rate: chanceInstance.dollar({ max: 60 })
  };
}

const data = [];

for (let i = 0; i < 10; i++) {
  data.push(createData());
}

class GigListings extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root} elevation={0}>
        {this.title()}
        {this.table()}
      </Paper>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography
          variant="h4"
          color="primary"
          className={classes.title}
        >
          Job Listings
        </Typography>
      </Fragment>
    );
  }

  table() {
    const { classes } = this.props;

    return (
      <div className={classes.table}>
        <Hidden smDown>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Task</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(n => {
                return (
                  <TableRow key={n.id}>
                    <TableCell>{n.dateTime}</TableCell>
                    <TableCell>{n.company}</TableCell>
                    <TableCell>{n.task}</TableCell>
                    <TableCell>{n.location}</TableCell>
                    <TableCell>{`${n.rate}/hr`}</TableCell>
                    <TableCell>
                      <Button
                        to="/workflows/worker-profile/gig-details"
                        component={Link}
                      >
                        Details
                      </Button>
                      <Button
                        className={classes.button}
                        variant="text"
                        color="primary"
                        component={Link}
                        to={window.location.pathname}
                      >
                        Apply
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Hidden>
        <Hidden mdUp>
          {data.map(n => {
            return (
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image={location_img}
                  title="Location image"
                />
                <CardContent>
                  <Typography
                    align="left"
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {`Rate: ${n.rate}/hr`}
                  </Typography>
                  <Typography align="left" component="p">
                    {n.company}
                    <br />
                    {n.task}
                    <br />
                    {n.location}
                    <br />
                    {n.dateTime}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    className={classes.button}
                    variant="text"
                    color="primary"
                    component={Link}
                    to={window.location.pathname}
                  >
                    Apply
                  </Button>
                  <Button
                    to="/workflows/worker-profile/gig-details"
                    component={Link}
                    size="small"
                  >
                    Details
                  </Button>
                </CardActions>
              </Card>
            );
          })}
        </Hidden>
      </div>
    );
  }

  button() {
    const { classes } = this.props;

    return (
      <Button
        className={classes.button}
        variant="text"
        color="primary"
        component={Link}
        to="/workflows/gig-worker/5"
      >
        Send SMS Notification
      </Button>
    );
  }
}

export default withStyles(styles)(GigListings);
