import { organizationsConstants } from "../actions";

const ORGANIZATIONS_INITIAL = {
  organization: null,
  organizations: null,
  isGettingOrganization: false,
  isGettingOrganizations: false,
  isUpdatingOrganization: false,
  isCreatingOrganization: false
};

export const organizationsReducer = (state = ORGANIZATIONS_INITIAL, action) => {
  switch (action.type) {
    case organizationsConstants.GET_ORGANIZATION_REQUEST:
      return {
        ...state,
        isGettingOrganization: true
      };
    case organizationsConstants.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        isGettingOrganization: false
      };
    case organizationsConstants.GET_ORGANIZATION_FAILURE:
      return {
        ...state,
        organization: null,
        isGettingOrganization: false
      };

    case organizationsConstants.GET_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        isGettingOrganizations: true
      };
    case organizationsConstants.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
        isGettingOrganizations: false
      };
    case organizationsConstants.GET_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        organizations: null,
        isGettingOrganizations: false
      };

    case organizationsConstants.UPDATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        isUpdatingOrganization: true
      };
    case organizationsConstants.UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        isUpdatingOrganization: false
      };
    case organizationsConstants.UPDATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        organization: null,
        isUpdatingOrganization: false
      };

    case organizationsConstants.CREATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        isCreatingOrganization: true
      };
    case organizationsConstants.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        isCreatingOrganization: false
      };
    case organizationsConstants.CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        organization: null,
        isCreatingOrganization: false
      };

    default:
      return state;
  }
};
