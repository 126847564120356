import axios from "axios";
import { BXSAPIURL } from "../constants";
import { snackbarActions } from "./snackbarActions";

// Types
export const templatesConstants = {
  GET_TEMPLATES_REQUEST: "GET_TEMPLATES_REQUEST",
  GET_TEMPLATES_SUCCESS: "GET_TEMPLATES_SUCCESS",
  GET_TEMPLATES_FAILURE: "GET_TEMPLATES_FAILURE",

  GET_TEMPLATE_REQUEST: "GET_TEMPLATE_REQUEST",
  GET_TEMPLATE_SUCCESS: "GET_TEMPLATE_SUCCESS",
  GET_TEMPLATE_FAILURE: "GET_TEMPLATE_FAILURE"
};

// Creators
export const templatesActions = {
  getTemplate,
  getTemplates
};

// Implementations
function getTemplates() {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: "/templates",
      baseURL: BXSAPIURL
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error ${
              error.response.status
            }: Templates request failed.`,
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: templatesConstants.GET_TEMPLATES_REQUEST };
  }
  function success(payload) {
    return { type: templatesConstants.GET_TEMPLATES_SUCCESS, payload };
  }
  function failure() {
    return { type: templatesConstants.GET_TEMPLATES_FAILURE };
  }
}

function getTemplate(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/templates/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: `Server error ${
              error.response.status
            }: Template request failed.`,
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: templatesConstants.GET_TEMPLATE_REQUEST };
  }
  function success(payload) {
    return { type: templatesConstants.GET_TEMPLATE_SUCCESS, payload };
  }
  function failure() {
    return { type: templatesConstants.GET_TEMPLATE_FAILURE };
  }
}
