import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, CircularProgress } from "@material-ui/core";
import { packsActions } from "../../actions";

const styles = {
  root: {
    textAlign: "left",
    maxWidth: 1200,
    margin: "0 auto"
  }
};

class ViewPack extends Component {
  componentDidMount() {
    this.props.getPack(this.props.match.params.id);
  }

  render() {
    const { pack, isGettingPack, classes } = this.props;

    if (isGettingPack) {
      return (
        <div>
          <CircularProgress />
          <Typography variant="h6">Loading Package</Typography>
        </div>
      );
    }

    if (!pack) {
      return <Typography variant="h6">Package Not Found</Typography>;
    }

    return (
      <Paper className={classes.root} elevation={0}>
        <Typography variant="subtitle1" color="secondary" gutterBottom>
          PACKAGE
        </Typography>
        <Typography variant="h3" color="primary" gutterBottom>
          {pack.name}
        </Typography>
        <Typography variant="h4" gutterBottom>
          No data available for this package.
        </Typography>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    pack: state.packs.pack,
    isGettingPack: state.packs.isGettingPack
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPack: packsActions.getPack
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ViewPack));
