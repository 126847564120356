import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";

const styles = {};

class OrganizationCell extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
    organizations: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  static defaultProps = {};

  state = {};

  render() {
    if (!this.props.organizations || this.props.organizations.length < 1) {
      return <TableCell>No organization</TableCell>;
    }
    const organization = this.props.organizations.find(
      org => org._id === this.props.organizationId
    );

    if (!organization) {
      return <TableCell>No organization</TableCell>;
    }

    return <TableCell>{organization.name}</TableCell>;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrganizationCell));
