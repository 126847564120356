import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper, Grid } from "@material-ui/core";
import Logo from "../shared/Logo2";
import Form from "./LogInForm";
import { PAUSE_RESET_PASSWORD_WIP } from "../../constants";

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  }
});

class LogInPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {}
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}>
        <Logo />
        <Paper style={{ padding: 30, marginTop: 30, marginBottom: 30 }}>
          {PAUSE_RESET_PASSWORD_WIP ? (
            <Typography variant="h4">Log In</Typography>
          ) : (
            <Grid container alignItems="center" justify="space-between">
              <Typography variant="h4">Log In</Typography>
              <Typography>
                <Link className={classes.link} to={"/forgotpassword"}>
                  Forgot password?
                </Link>
              </Typography>
            </Grid>
          )}
          <Form />
        </Paper>
        <Typography>
          Interested in joining BLOCKxStudio?{" "}
          <Link className={classes.link} to={"/signup"}>
            Request an Invite
          </Link>
        </Typography>
      </div>
    );
  }
}

LogInPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LogInPage));
