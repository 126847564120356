import { usersConstants } from "../actions";

const USERS_INITIAL = {
  user: null,
  users: null,
  isGettingUser: false,
  isGettingUsers: false,
  isDeletingUser: false
};

export const usersReducer = (state = USERS_INITIAL, action) => {
  switch (action.type) {
    case usersConstants.GET_USER_REQUEST:
      return {
        ...state,
        isGettingUser: true
      };
    case usersConstants.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isGettingUser: false
      };
    case usersConstants.GET_USER_FAILURE:
      return {
        ...state,
        user: null,
        isGettingUser: false
      };

    case usersConstants.GET_USERS_REQUEST:
      return {
        ...state,
        isGettingUsers: true
      };
    case usersConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isGettingUsers: false
      };
    case usersConstants.GET_USERS_FAILURE:
      return {
        ...state,
        users: null,
        isGettingUsers: false
      };

    case usersConstants.DELETE_USER_REQUEST:
      return {
        ...state,
        isDeletingUser: true
      };
    case usersConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isDeletingUser: false
      };
    case usersConstants.DELETE_USER_FAILURE:
      return {
        ...state,
        user: null,
        isDeletingUser: false
      };

    default:
      return state;
  }
};
