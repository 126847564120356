import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../shared/Loading";
import CardGrid from "../shared/CardGrid";
import { workflowsActions } from "../../actions";

const styles = {};

class ListWorkflows extends React.Component {
  static protoTypes = {
    showcase: PropTypes.bool
  };

  static defaultProps = {
    showcase: false
  };

  componentDidMount() {
    this.props.getWorkflows({ purchasedBy: { $exists: false } });
  }

  render() {
    const { workflows, isGettingWorkflows, showcase } = this.props;

    if (isGettingWorkflows) {
      return <Loading text="Loading Workflows" />;
    }

    if (!workflows) {
      return <Typography variant="h6">No Workflows Available</Typography>;
    }

    return (
      <CardGrid
        objects={workflows}
        link="/contact"
        linkText="Contact Us"
        appendId={false}
        type="WORKFLOW"
        filterObjects={
          showcase ? objects => objects.filter(object => object.showcase) : null
        }
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    workflows: state.workflows.workflows,
    isGettingWorkflows: state.workflows.isGettingWorkflows
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWorkflows: workflowsActions.getWorkflows
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ListWorkflows));
