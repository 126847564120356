import React from "react";
import { Button, CardActions } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DoneIcon from "@material-ui/icons/Done";
import Divider from "@material-ui/core/Divider";
import DappScreenshot from "../../images/BxS-SupplyChain-Dapp.gif";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    textAlign: "center",
    margin: "0 auto"
  }
});

class SupplyChain extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Track Your Shipments on Blockchain
        </Typography>
        <Typography variant="h5" color="secondary" gutterBottom>
          End-to-end supply chain visibility made easy with BLOCKxStudio.
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
            <ListItemText primary="Trace cargo at each carrier step of the shipment" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
            <ListItemText primary="Reduce logistics coordination time from days to minutes" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
            <ListItemText primary="Consolidate immutable shared records across multiple shipping partners" />
          </ListItem>
        </List>
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={"../TrialsContact"}
          >
            START YOUR FREE TRIAL TODAY
          </Button>
        </CardActions>
        <Divider style={{ margin: "5% 0" }} />
        <Paper className={classes.root} elevation={1}>
          <img
            style={{ width: "100%" }}
            className="screenshot"
            alt="BLOCKxStudio Supply Chain Dapp"
            src={DappScreenshot}
          />
        </Paper>
        <Divider style={{ margin: "5% 0" }} />
        <Typography variant="h4" color="secondary" gutterBottom>
          What our supply chain customers say about BLOCKxStudio.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Eric Klunder, Lynden International:
        </Typography>
        <Typography variant="body1" gutterBottom>
          "It was great to be able to track in almost real time where the
          shipment was and how it was getting handed over from one carrier to
          the other. Getting text message notification saves me a lot of effort
          emailing and calling in the early hours, late nights and weekends.
          Knowing that I also have a permanent record on blockchain gives me
          peace of mind."
        </Typography>
        <Typography variant="h6" gutterBottom>
          Martin Wayss, PATH:
        </Typography>
        <Typography variant="body1" gutterBottom>
          "The shipments went smoothly. It was really helpful to get
          notifications where the shipment was. My team wants to know, is the
          shipment getting there? They want to be relieved of the stress around
          knowing where it was. Previously I had to email our logistics partner,
          but now I could see the progression through the journey."
        </Typography>
        <Divider style={{ margin: "5% 0" }} />
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={"../TrialsContact"}
          >
            START YOUR FREE TRIAL TODAY
          </Button>
        </CardActions>
      </div>
    );
  }
}

export default withStyles(styles)(SupplyChain);
