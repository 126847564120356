import { invitesConstants } from "../actions";

const INVITES_INITIAL = {
  invites: null,
  invite: null,
  isGettingInvite: false,
  isGettingInvites: false,
  isDeletingInvite: false,
  isSendingInvite: false,
  requests: null,
  request: null,
  isGettingRequest: false,
  isGettingRequests: false,
  isDeletingRequest: false,
  isSendingRequest: false
};

export const invitesReducer = (state = INVITES_INITIAL, action) => {
  switch (action.type) {
    case invitesConstants.GET_INVITE_REQUEST:
      return {
        ...state,
        isGettingInvite: true
      };
    case invitesConstants.GET_INVITE_SUCCESS:
      return {
        ...state,
        invite: action.payload,
        isGettingInvite: false
      };
    case invitesConstants.GET_INVITE_FAILURE:
      return {
        ...state,
        invite: null,
        isGettingInvite: false
      };

    case invitesConstants.GET_INVITES_REQUEST:
      return {
        ...state,
        isGettingInvites: true
      };
    case invitesConstants.GET_INVITES_SUCCESS:
      return {
        ...state,
        invites: action.payload,
        isGettingInvites: false
      };
    case invitesConstants.GET_INVITES_FAILURE:
      return {
        ...state,
        invites: null,
        isGettingInvites: false
      };

    case invitesConstants.DELETE_INVITE_REQUEST:
      return {
        ...state,
        isDeletingInvite: true
      };
    case invitesConstants.DELETE_INVITE_SUCCESS:
      return {
        ...state,
        isDeletingInvite: false
      };
    case invitesConstants.DELETE_INVITE_FAILURE:
      return {
        ...state,
        isDeletingInvite: false
      };

    case invitesConstants.SEND_INVITE_REQUEST:
      return {
        ...state,
        isSendingInvite: true
      };
    case invitesConstants.SEND_INVITE_SUCCESS:
      return {
        ...state,
        invite: action.payload,
        isSendingInvite: false
      };
    case invitesConstants.SEND_INVITE_FAILURE:
      return {
        ...state,
        invite: null,
        isSendingInvite: false
      };

    case invitesConstants.GET_REQUEST_REQUEST:
      return {
        ...state,
        isGettingInvite: true
      };
    case invitesConstants.GET_REQUEST_SUCCESS:
      return {
        ...state,
        invite: action.payload,
        isGettingInvite: false
      };
    case invitesConstants.GET_REQUEST_FAILURE:
      return {
        ...state,
        invite: null,
        isGettingInvite: false
      };

    case invitesConstants.GET_REQUESTS_REQUEST:
      return {
        ...state,
        isGettingRequests: true
      };
    case invitesConstants.GET_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.payload,
        isGettingRequests: false
      };
    case invitesConstants.GET_REQUESTS_FAILURE:
      return {
        ...state,
        requests: null,
        isGettingRequests: false
      };

    case invitesConstants.DELETE_REQUEST_REQUEST:
      return {
        ...state,
        isDeletingRequest: true
      };
    case invitesConstants.DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        isDeletingRequest: false
      };
    case invitesConstants.DELETE_REQUEST_FAILURE:
      return {
        ...state,
        isDeletingRequest: false
      };

    case invitesConstants.SEND_REQUEST_REQUEST:
      return {
        ...state,
        isSendingRequest: true
      };
    case invitesConstants.SEND_REQUEST_SUCCESS:
      return {
        ...state,
        request: action.payload,
        isSendingRequest: false
      };
    case invitesConstants.SEND_REQUEST_FAILURE:
      return {
        ...state,
        request: null,
        isSendingRequest: false
      };

    case invitesConstants.CLEAR_REQUEST:
      return {
        ...state,
        request: null
      };

    default:
      return state;
  }
};
