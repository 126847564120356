import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  TextField,
  Typography,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../shared/Loading";
import { invitesActions } from "../../actions";

const styles = {
  root: {
    maxWidth: 500,
    margin: "50px 0 30px"
  },
  textField: {
    margin: "5px 0"
  },
  button: {
    margin: "25px 0 10px"
  },
  rolesFormLabel: {
    marginTop: 30
  }
};

class InviteForm extends Component {
  static protoTypes = {
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    organizationId: PropTypes.string
  };

  static defaultProps = {
    organizationId: null
  };

  state = {
    email: null,
    role: null
  };

  render() {
    const { isSendingInvite, classes } = this.props;

    if (isSendingInvite) {
      return <Loading text="Sending Invite" />;
    }

    return (
      <div className={classes.root}>
        <Typography variant="h5">Invite New User</Typography>
        <TextField
          id="email"
          label="Email"
          fullWidth
          onChange={event => this.setState({ email: event.target.value })}
          className={classes.textField}
        />
        <FormLabel component="legend" className={classes.rolesFormLabel}>
          Choose Role for this User
        </FormLabel>
        <RadioGroup
          aria-label="roles"
          name="roles"
          value={this.state.role}
          onChange={event => this.setState({ role: event.target.value })}
        >
          {this.props.roles.map(role => (
            <FormControlLabel
              key={role.id}
              value={role.id}
              control={<Radio color="primary" />}
              label={role.name}
            />
          ))}
        </RadioGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.handleSubmit()}
          className={classes.button}
          disabled={!this.isValid()}
        >
          Send Invite
        </Button>
      </div>
    );
  }

  isValid() {
    if (!this.state.email || this.state.email.length < 1) {
      return false;
    }

    if (!this.state.role) {
      return false;
    }

    return true;
  }

  handleSubmit() {
    const { sendInvite } = this.props;

    const values = {
      email: this.state.email,
      role: this.state.role,
      organizationId: this.props.organizationId
    };

    sendInvite(values);

    this.resetFormState();
  }

  resetFormState() {
    this.setState({
      email: null,
      role: null
    });
  }
}

const mapStateToProps = state => {
  return {
    isSendingInvite: state.invites.isSendingInvite
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendInvite: invitesActions.sendInvite
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InviteForm));
