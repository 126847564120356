import { packsConstants } from "../actions";

const PACKS_INITIAL = {
  pkg: null,
  packs: null,
  isGettingPack: false,
  isGettingPacks: false
};

export const packsReducer = (state = PACKS_INITIAL, action) => {
  switch (action.type) {
    case packsConstants.GET_PACK_REQUEST:
      return {
        ...state,
        isGettingPack: true
      };
    case packsConstants.GET_PACK_SUCCESS:
      return {
        ...state,
        pack: action.payload,
        isGettingPack: false
      };
    case packsConstants.GET_PACK_FAILURE:
      return {
        ...state,
        pack: null,
        isGettingPack: false
      };

    case packsConstants.GET_PACKS_REQUEST:
      return {
        ...state,
        isGettingPacks: true
      };
    case packsConstants.GET_PACKS_SUCCESS:
      return {
        ...state,
        packs: action.payload,
        isGettingPacks: false
      };
    case packsConstants.GET_PACKS_FAILURE:
      return {
        ...state,
        packs: null,
        isGettingPacks: false
      };

    default:
      return state;
  }
};
