import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Hidden } from "../../../node_modules/@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ListServices from "../services/ListServices";
import Contact from "../contact/Contact";
import TrialsContact from "../contact/TrialsContact";
import Privacy from "../terms/Privacy";
import Terms from "../terms/TermsConditions";
import DeadEnd from "../shared/DeadEnd";
import header_templates from "../../images/header_templates.png";
import header_contact from "../../images/header_contact.png";
import header_detail from "../../images/header_detail.png";
import header_provisioning from "../../images/header_provisioning.png";
import header_services from "../../images/header_services.png";
import header_studio from "../../images/header_studio.png";
import header_cannabis from "../../images/header_cannabis.jpeg";
import header_supplychain from "../../images/header_supply_chain.jpeg";
import header_private_sessions from "../../images/header_private_sessions.jpg";
import Nav from "../navbar/Nav";
import Footer from "../footer/Footer";
import ListLayout from "./ListLayout";
import ViewTemplate from "../templates/ViewTemplate";
import ViewWorkflow from "../workflows/ViewWorkflow";
import ViewPack from "../packs/ViewPack";
import AllTemplates from "../templates/AllTemplates";
import AllWorkflows from "../workflows/AllWorkflows";
import AllPacks from "../packs/AllPacks";
import Dashboard from "../studio/Dashboard";
import PrivateSessions from "../services/PrivateSessions";
import Admin from "../admin/Admin";
import SupplyChain from "../trials/SupplyChain";
import Cannabis from "../trials/Cannabis";

const headerHeight = 200;

const styles = theme => ({
  mobile: {
    padding: theme.page.padding,
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`
  },
  desktop: {
    padding: theme.page.padding,
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight +
      headerHeight}px)`
  }
});

class HeaderLayout extends Component {
  renderHeader = imageUrl => {
    return (
      <div
        style={{
          width: "100%",
          height: headerHeight,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      />
    );
  };

  switchHeader() {
    return (
      <Switch>
        <Route
          exact
          path="/templates"
          component={() => this.renderHeader(header_templates)}
        />
        <Route
          path="/templates/:id"
          component={() => this.renderHeader(header_templates)}
        />
        <Route
          path="/services"
          component={() => this.renderHeader(header_private_sessions)}
        />
        <Route
          path="/contact"
          component={() => this.renderHeader(header_contact)}
        />
        <Route
          path="/privacy"
          component={() => this.renderHeader(header_contact)}
        />
        <Route
          path="/terms"
          component={() => this.renderHeader(header_contact)}
        />
        <Route
          path="/workflow"
          component={() => this.renderHeader(header_detail)}
        />
        <Route
          path="/studio"
          component={() => this.renderHeader(header_studio)}
        />
        <Route
          path="/provisioning"
          component={() => this.renderHeader(header_provisioning)}
        />
        {/* <Route
          path="/services/privatesessions"
          component={() => this.renderHeader(header_private_sessions)}
        /> */}
        <Route
          path="/trials/cannabis"
          component={() => this.renderHeader(header_cannabis)}
        />
        <Route
          path="/trials/supplychain"
          component={() => this.renderHeader(header_supplychain)}
        />
        <Route component={() => this.renderHeader(header_templates)} />
      </Switch>
    );
  }

  switchPage() {
    return (
      <Switch>
        <Route exact path="/templates" component={ListLayout} />
        <Route exact path="/templates/templates" component={AllTemplates} />
        <Route exact path="/templates/workflows" component={AllWorkflows} />
        <Route exact path="/templates/packs" component={AllPacks} />

        <Route path="/templates/templates/:id" component={ViewTemplate} />
        <Route path="/templates/workflows/:id" component={ViewWorkflow} />
        <Route path="/templates/packs/:id" component={ViewPack} />

        <Route exact path="/services" component={ListServices} />
        <Route
          exact
          path="/services/privatesessions"
          component={PrivateSessions}
        />
        <Route exact path="/trials/supplychain" component={SupplyChain} />
        <Route exact path="/trials/cannabis" component={Cannabis} />
        <Route path="/contact" component={Contact} />
        <Route path="/trialscontact" component={TrialsContact} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={Terms} />

        <Route
          path="/admin"
          component={
            !this.props.authUser || !this.props.authUser.internalRole
              ? DeadEnd
              : Admin
          }
        />

        <Route
          exact
          path="/studio/dashboard"
          component={!this.props.authUser ? DeadEnd : Dashboard}
        />
        <Route component={DeadEnd} />
      </Switch>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Nav />
        <Hidden smDown>
          {this.switchHeader()}
          <div className={classes.desktop}>{this.switchPage()}</div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.mobile}>{this.switchPage()}</div>
        </Hidden>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: state.auth.user
  };
};

export default connect(mapStateToProps)(withStyles(styles)(HeaderLayout));
