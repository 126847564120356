import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  box: theme.page.box,
  title: theme.page.title,
  section: theme.page.title
});

class Privacy extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.box}>
        <div className={classes.title}>
          <Typography variant="h3" color="primary" gutterBottom>
            BLOCKxStudio.com Privacy Policy Statement
          </Typography>
          <Typography variant="h5" color="secondary" gutterBottom>
            Protecting your private information is our policy. This Statement of
            Privacy applies to BLOCKxStudio.com and governs data collection and
            usage. For the purposes of this Privacy Policy, unless otherwise
            noted, all references to BLOCKxStudio.com include Last Myle, LLC. By
            using the BLOCKxStudio.com website, you consent to the data
            practices described in this Statement.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Collection of your Personal Information
          </Typography>
          <Typography gutterBottom>
            BLOCKxStudio.com may also collect anonymous demographic information,
            which is not unique to you.
          </Typography>
          <Typography gutterBottom>
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services available at BLOCKxStudio.com. These
            may include: (a) registering for an account on BLOCKxStudio.com; (b)
            entering a sweepstakes or contest sponsored by us or one of our
            partners; (c) signing up or special offers from selected third
            parties; (d) sending us an email message; (e) submitting your credit
            card or other payment information when ordering and purchasing
            products and services on BLOCKxStudio.com. To wit, we will use your
            information for, but not be limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            may also gather additional personal or non-personal information in
            the future.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Use of your Personal Information
          </Typography>
          <Typography gutterBottom>
            BLOCKxStudio.com collects and uses your personal information to
            operate its website(s) and deliver the products and services you
            have requested. BLOCKxStudio.com may also use your personally
            identifiable information to inform you of other products or services
            available from LastMyle, LLC and its affiliates.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Sharing Information with Third Parties
          </Typography>
          <Typography gutterBottom>
            BLOCKxStudio.com does not sell, rent or lease its customer lists to
            third parties.
          </Typography>
          <Typography gutterBottom>
            LasyMyle.co may, from time to time, contact you on behalf of
            external business partners about a particular offering that may be
            of interest to you. In those cases, your unique personally
            identifiable information (email, name, address, telephone number) is
            transferred to the third party. BLOCKxStudio.com may share data with
            trusted partners to help perform statistical analysis, send you
            email or postal mail, provide customer support, or arrange for
            deliveries. All such third party are prohibited from using your
            personal information except to provide these services to
            BLOCKxStudio.com, and they are required to maintain the
            confidentiality of your information.
          </Typography>
          <Typography gutterBottom>
            BLOCKxStudio.com may disclose your personal information, without
            notice, if required to do so by law or in good faith belief that
            such action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on Last Myle, LLC or
            BLOCKxStudio.com; (b) protect and defend the rights or property of
            BLOCKxStudio.com; and/or (c) act under exigent circumstances to
            protect the personal safety of uses of BLOCKxStudio.com, or the
            public.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Tracking User Behavior
          </Typography>
          <Typography gutterBottom>
            BLOCKxStudio.com may keep track of the websites and pages our users
            visit within BLOCKxStudio.com, in order to determine what
            BLOCKxStudio.com services are the most popular. This data is used to
            deliver customized content and advertising within BLOCKxStudio.com
            to customers whose behavior indicates that they are interested in a
            particular subject area.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Automatically Collected Information
          </Typography>
          <Typography gutterBottom>
            Information about your computer hardware and software may be
            automatically collected by BLOCKxStudio.com. This information can
            include: your IP address, browser type, domain names, access times
            and referring website addresses. This information is used for the
            operation of the service, to maintain quality of the service, and to
            provide general statistics regarding use of the BLOCKxStudio.com
            website.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Children Under Thirteen
          </Typography>
          <Typography gutterBottom>
            BLOCKxStudio.com does not knowingly collect personally identifiable
            information from children under the age of thirteen. If you are
            under the age of thirteen, you must ask your parent or guardian for
            permission to use BLOCKxStudio.com.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Opt-Out and Unsubscribe from Third Party Communications
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC respects your privacy and gives you an opportunity to
            opt-out of receiving announcements of certain information. Users may
            opt-out of receiving any or all communications from third party
            partners of Last Myle, LLC by contacting us here:
          </Typography>
          <Typography gutterBottom>Web page: LastMyle.co</Typography>
          <Typography gutterBottom>Email: bernie@lastmyle.co</Typography>
          <Typography gutterBottom>Telephone: 206-499-3342</Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Changes to this Statement
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC reserves the right to change this Privacy Policy from
            time to time. We will notify you about significant changes in the
            way we treat personal information by sending a notice to the primary
            email address specified in your account, by placing a prominent
            notice on BLOCKxStudio.com, and/or by updating any privacy
            information on this page. Your continued use of BLOCKxStudio.com
            and/or services or products available through BLOCKxStudio.com after
            such modifications will constitute your: (a) acknowledgement of the
            modified Privacy Policy; and (b) agreement to abide and be bound by
            that Privacy Policy.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Contact Information
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC welcomes your questions or comments regarding this
            Privacy Policy. If you believe that Last Myle LLC has not adhered to
            this Privacy Policy, please contact Bernard Hansen at:
          </Typography>
          <Typography gutterBottom>Last Myle, LLC</Typography>
          <Typography gutterBottom>220 Second Avenue</Typography>
          <Typography gutterBottom>Seattle, WA 98104</Typography>
        </div>
        <div className={classes.section}>
          <Typography gutterBottom>
            Email address: bernie@lastmyle.co
          </Typography>
          <Typography gutterBottom>Telephone number: 206-499-3342</Typography>
          <Typography gutterBottom>Effective June 5, 2018</Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Privacy);
