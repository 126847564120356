import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import ContactForm from "./ContactForm";

const styles = {};

class Contact extends React.Component {
  render() {
    return (
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="h5" color="secondary" gutterBottom>
          Request a BLOCKxStudio demo or access to private demo environments.
          We're also available to give talks and lead workshops at blockchain
          community events.
        </Typography>
        {/* <Typography variant="h5" color="secondary" gutterBottom>
          Or send us your blockchain questions at info@blockxstudio.com or +1
          415 294 0983.
        </Typography> */}
        <Paper style={{ maxWidth: 600, margin: "30px auto 0px", padding: 40 }}>
          <ContactForm />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Contact);
