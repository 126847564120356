import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper, Hidden, Button, Toolbar } from "@material-ui/core";
import ListWorkflows from "./ListWorkflows";

const styles = {
  paper: {
    marginTop: 30,
    marginBottom: 50,
    padding: "40px"
  },
  toolbar: {
    marginBottom: 20
  },
  button: {
    marginTop: 20
  }
};

class Workflows extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Typography
            variant="h4"
            color="secondary"
            style={{ flexGrow: 1 }}
          >
            Workflows
          </Typography>
          <Hidden xsDown>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={"/templates/workflows"}
            >
              More Workflows
            </Button>
          </Hidden>
        </Toolbar>
        <ListWorkflows />
        <Hidden smUp>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={"/templates/workflows"}
            fullWidth
            className={classes.button}
          >
            More Workflows
          </Button>
        </Hidden>
      </Paper>
    );
  }
}

export default withStyles(styles)(Workflows);
