import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper, Grid, Hidden } from "@material-ui/core";
import backgroundImage from "../../images/header_homepage.png";

const styles = theme => ({
  text1: {
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    textTransform: "uppercase"
  },
  text2: {
    color: theme.palette.primary.contrastText
  }
});

const Header = props => {
  const { classes } = props;
  return (
    <Paper
      elevation={0}
      style={{
        width: "100%",
        height: 600,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justify="center"
        direction="row"
        style={{ height: "inherit" }}
      >
        <Grid item style={{ maxWidth: 940, margin: 10 }}>
          <Hidden smDown>
            <Typography
              variant="h5"
              className={classes.text1}
              align="center"
              gutterBottom
            >
              UNLOCK BLOCKCHAIN FOR YOUR BUSINESS
            </Typography>
            <Typography variant="h3" className={classes.text2} align="center">
              Harness the power of blockchain in 15 minutes with easy-to-use
              embeddable templates.
            </Typography>
          </Hidden>
          <Hidden mdUp>
            <Typography
              variant="h5"
              className={classes.text1}
              align="center"
              gutterBottom
            >
              UNLOCK BLOCKCHAIN FOR YOUR BUSINESS
            </Typography>
            <Typography
              variant="h4"
              className={classes.text2}
              align="center"
            >
              Harness the power of blockchain in 15 minutes with easy-to-use
              embeddable templates.
            </Typography>
          </Hidden>
        </Grid>
      </Grid>
    </Paper>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
