import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from "@material-ui/core";
import Loading from "../shared/Loading";
import OrganizationCell from "./OrganizationCell";
import { usersActions, organizationsActions } from "../../actions";

const styles = {};

class UserList extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  state = {};

  rehydrateState() {
    this.props.getUsers();
  }

  componentDidMount() {
    this.rehydrateState();
    this.props.getOrganizations();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isDeletingUser) {
      this.rehydrateState();
    }
  }

  render() {
    const {
      users,
      isGettingUsers,
      isGettingOrganizations,
      isDeletingUser,
      deleteUser
    } = this.props;

    if (isGettingUsers) {
      return <Loading text="Loading Userbase..." />;
    }

    if (isGettingOrganizations) {
      return <Loading text="Loading Organizations..." />;
    }

    if (isDeletingUser) {
      return <Loading text="Updating Userbase..." />;
    }

    if (!users || users.length < 1) {
      return (
        <div className={this.props.className}>
          <Typography variant="h5" gutterBottom>
            Userbase
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            No Users to List
          </Typography>
        </div>
      );
    }

    return (
      <div className={this.props.className}>
        <Typography variant="h4" gutterBottom>
          Userbase
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Internal Role</TableCell>
              <TableCell>Customer Role</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => {
              return (
                <TableRow hover key={user._id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.internalRole}</TableCell>
                  <TableCell>{user.customerRole}</TableCell>
                  <OrganizationCell
                    organizationId={user.organization}
                    organizations={this.props.organizations}
                  />
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => deleteUser(user._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    isGettingUsers: state.users.isGettingUsers,
    isDeletingUser: state.users.isDeletingUser,
    isGettingOrganization: state.organizations.isGettingOrganizations,
    organizations: state.organizations.organizations
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsers: usersActions.getUsers,
      deleteUser: usersActions.deleteUser,
      getOrganizations: organizationsActions.getOrganizations
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserList));
