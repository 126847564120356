import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardActions,
  Typography,
  Button,
  Grid,
  CardContent
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = {
  title: {
    marginBottom: 16,
    fontSize: 14
  },
  card: {
    height: "100%"
  }
};

class CardGrid extends React.Component {
  static protoTypes = {
    objects: PropTypes.array.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    filterObjects: PropTypes.func,
    type: PropTypes.string,
    appendId: PropTypes.bool
  };

  static defaultProps = {
    maxObjects: null,
    type: "",
    appendId: false,
    filterObjects: null
  };

  render() {
    const { classes, objects, type, linkText, filterObjects } = this.props;
    const list = filterObjects ? filterObjects(objects) : objects;

    return (
      <Grid container spacing={16} alignItems="stretch">
        {list.map(object => (
          <Grid item xs={12} md={4} key={object._id}>
            <Grid
              container
              direction="column"
              justify="space-between"
              component={Card}
              className={classes.card}
            >
              <Grid item component={CardContent}>
                <Typography className={classes.title} color="textSecondary">
                  {type}
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  {object.name}
                </Typography>
                <Typography>{object.description}</Typography>
              </Grid>
              <Grid item component={CardActions}>
                <Button
                  variant="text"
                  color="primary"
                  component={Link}
                  to={this.calculateLink(object._id)}
                >
                  {linkText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }

  calculateLink(id) {
    const { link, appendId } = this.props;

    return appendId ? `${link}/${id}` : link;
  }
}

export default withStyles(styles)(CardGrid);
