import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  LinearProgress
} from "@material-ui/core";
import profile_img from "../../../../images/profile_default.png";
import SimpleListMenu from "../shared/SimpleListMenu";

const styles = {
  root: {
    // padding: 30,
    margin: "20px auto",
    textAlign: "left",
    maxWidth: 960
  },
  title: {},
  grid: {
    flexGrow: 1,
    margin: "20px 0"
  },
  submitButton: {
    marginTop: 30
  },
  jobPrefsTitle: {},
  paper: {
    padding: 10,
    margin: 10
  }
};

class Profile extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes, worker } = this.props;

    if (!worker) {
      return (
        <Typography variant="h6">Worker information unavailable.</Typography>
      );
    }

    return (
      <div className={classes.root}>
        {this.basicInfo()}
        {this.preferences()}
        {this.backgroundCheck()}
      </div>
    );
  }

  basicInfo() {
    const { classes, worker } = this.props;

    return (
      <div className={classes.paper}>
        <Grid container className={classes.grid} spacing={16}>
          <Grid item xs={12} sm={4}>
            <img
              src={profile_img}
              alt="Worker Worfklow Profile"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" className={classes.sectionTitle}>
              Basic Information
            </Typography>
            <TextField
              id="full-name"
              label="Full Name"
              className={classes.textField}
              value={worker.name}
              margin="normal"
              fullWidth
            />
            <TextField
              id="phone-number"
              label="Phone Number"
              className={classes.textField}
              value={worker.phone}
              margin="normal"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  preferences() {
    const { classes } = this.props;
    return (
      <div className={classes.paper}>
        <Typography variant="h5" className={classes.jobPrefsTitle}>
          Job Preferences
        </Typography>
        <List component="nav">
          <Typography variant="subtitle1">Job Location</Typography>
          <ListItem>{this.jobLocation()}</ListItem>
          <Typography variant="subtitle1">Job Time</Typography>
          <ListItem>{this.jobTime()}</ListItem>
          <Typography variant="subtitle1">Job Type</Typography>
          <ListItem>{this.jobType()}</ListItem>
        </List>
      </div>
    );
  }

  jobLocation() {
    return (
      <Fragment>
        <FormControlLabel
          control={<Checkbox checked={true} value="checkedA" />}
          label="Seattle"
        />

        <FormControlLabel
          control={<Checkbox checked={true} value="checkedA" />}
          label="Bellevue"
        />
        <FormControlLabel
          control={<Checkbox checked={false} value="checkedA" />}
          label="Renton"
        />
      </Fragment>
    );
  }

  jobTime() {
    return (
      <Fragment>
        <SimpleListMenu
          label="Earliest Time"
          options={["6:00am", "6:30am", "7:00am", "7:30am"]}
        />
        <SimpleListMenu
          label="Latest Time"
          options={["6:00pm", "6:30pm", "7:00pm", "7:30pm"]}
        />
        {/* <FormControlLabel
          control={<Checkbox checked={false} value="checkedA" />}
          label="I don't care about timing"
        /> */}
      </Fragment>
    );
  }

  jobType() {
    return (
      <Fragment>
        <FormControlLabel
          control={<Checkbox checked={true} value="checkedA" />}
          label="Pet Sitting"
        />
        <FormControlLabel
          control={<Checkbox checked={true} value="checkedA" />}
          label="Small Tasks"
        />
        <FormControlLabel
          control={<Checkbox checked={false} value="checkedA" />}
          label="Moving"
        />
      </Fragment>
    );
  }

  backgroundCheck() {
    const { classes } = this.props;
    return (
      <div className={classes.paper}>
        <Typography
          align="left"
          variant="h5"
          className={classes.sectionTitle}
          gutterBottom
        >
          Background Check Status
        </Typography>
        <Typography align="left" variant="subtitle1" gutterBottom>
          Pending Approval...
        </Typography>
        <LinearProgress
          color="primary"
          variant="determinate"
          value={74}
          style={{
            padding: 10
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    worker: state.gigWorker.worker
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Profile));
