import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import InvitesList from "../invites/InvitesList";
import InviteForm from "../invites/InviteForm";

import { getRoleById, getRolesByIds } from "../../constants/Roles";

const styles = {
  list: {
    marginTop: 40
  }
};

class InternalInvites extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    const userRole = getRoleById(this.props.internalRole);

    if (!userRole) {
      return (
        <Typography variant="subtitle1">
          Error: You do not have a valid internal team role.
        </Typography>
      );
    }

    return (
      <Fragment>
        <Typography variant="h4" gutterBottom>
          Manage Invites
        </Typography>
        {!userRole.canInvite && (
          <Typography variant="subtitle1">
            As a {userRole.name}, you cannot add users to the BLOCKxStudio
            Internal Team or invite a new Customer Owner.
          </Typography>
        )}
        {userRole.canInvite && (
          <Fragment>
            <Typography variant="subtitle1" gutterBottom>
              You can invite new users to the BLOCKxStudio Internal Team or
              invite a new Customer Owner to create their organization.
            </Typography>
            <InvitesList
              filterRoles={userRole.canInvite}
              className={classes.list}
            />
            <InviteForm roles={getRolesByIds(userRole.canInvite)} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  internalRole: state.auth.user.internalRole
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InternalInvites));
