import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DeadEnd from "../shared/DeadEnd";
import GigWorkerWorkflow from "../workflows/examples/GigWorker/Workflow";
import GigWorkerAdminWorkflow from "../workflows/examples/GigWorkerAdmin/Workflow";
import GigWorkerMemberWorkflow from "../workflows/examples/GigWorkerMember/Workflow";

class HeadlessLayout extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/workflows/worker-management"
            component={GigWorkerAdminWorkflow}
          />
          <Route
            path="/workflows/worker-profile"
            component={GigWorkerMemberWorkflow}
          />
          <Route path="/workflows/gig-worker" component={GigWorkerWorkflow} />
          <Route component={DeadEnd} />
        </Switch>
      </div>
    );
  }
}

export default HeadlessLayout;
