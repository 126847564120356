import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../shared/Loading";
import CardGrid from "../shared/CardGrid";
import { packsActions } from "../../actions";

const styles = {};

class ListPacks extends React.Component {
  static protoTypes = {
    showcase: PropTypes.bool
  };

  static defaultProps = {
    showcase: false
  };

  componentDidMount() {
    this.props.getPacks();
  }

  render() {
    const { packs, isGettingPacks, showcase } = this.props;

    if (isGettingPacks) {
      return <Loading text="Loading Packages" />;
    }

    if (!packs) {
      return <Typography variant="h6">No Packages Available</Typography>;
    }

    return (
      <CardGrid
        objects={packs}
        link="/contact"
        linkText="Contact Us"
        appendId={false}
        type="PACKAGE"
        filterObjects={
          showcase ? objects => objects.filter(object => object.showcase) : null
        }
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    packs: state.packs.packs,
    isGettingPacks: state.packs.isGettingPacks
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPacks: packsActions.getPacks
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ListPacks));
