export const ROLES = [
  {
    id: "bxs-admin",
    name: "BXS Admin",
    shortName: "Admin",
    canInvite: ["bxs-admin", "bxs-developer", "customer-owner"],
    description: "",
    isInternal: true
  },
  {
    id: "bxs-developer",
    name: "BXS Developer",
    shortName: "Developer",
    canInvite: null,
    description: "",
    isInternal: true
  },
  {
    id: "customer-owner",
    name: "Customer Owner",
    shortName: "Owner",
    canInvite: ["customer-admin", "customer-developer", "customer-producer"],
    description:
      "Owner pays for the account. Owner can invite, edit and remove users to the account and assign their roles. An account can only have one owner.",
    isInternal: false
  },
  {
    id: "customer-admin",
    name: "Customer Admin",
    shortName: "Admin",
    canInvite: ["customer-admin", "customer-developer", "customer-producer"],
    description:
      "Administrators have full access to invite, edit and remove users, templates and provision embeds. ",
    isInternal: false
  },
  {
    id: "customer-developer",
    name: "Customer Developer",
    shortName: "Developer",
    canInvite: null,
    description:
      "Developers have access the BLOCKxStudio develop portal to create, edit and publish smart contract templates.",
    isInternal: false
  },
  {
    id: "customer-producer",
    name: "Customer Producer",
    shortName: "Producer",
    canInvite: null,
    description:
      "Producers can browse and select smart contract templates, compose workflows to deploy them into their enterprise systems.",
    isInternal: false
  },
  {
    id: "customer-consumer",
    name: "Customer Consumer",
    shortName: "Consumer",
    canInvite: null,
    description:
      "Consumers have read-only access to a DApp owned by a Customer.",
    isInternal: false
  }
];

// Expects string
// Should return an object
export function getRoleById(id) {
  if (!id) {
    return null;
  }
  return ROLES.find(role => role.id === id);
}

// Expects an array of strings
// Should return an array of objects
export function getRolesByIds(ids) {
  if (!ids) {
    return null;
  }
  return ids.map(id => getRoleById(id));
}

// Expects a string
// Should return a string
export function getNameById(id) {
  if (!id) {
    return null;
  }

  const foundName = ROLES.find(role => role.id === id);

  return !foundName ? null : foundName.name;
}
