import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { gigWorkerActions } from "../../../../actions";

const styles = {
  root: {
    padding: 30,
    margin: "40px 20px",
    textAlign: "center"
  },
  title: {},
  table: {
    margin: "0 auto",
    overflowX: "auto"
  },
  button: {
    margin: 5
  },
  toolbar: {
    marginBottom: 10
  }
};

class ListWorkers extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.title()}
        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          className={classes.toolbar}
        >
          <Grid item xs={12} sm={4}>
            <TextField label="Search Workers" fullWidth />
          </Grid>
          <Grid item>
            {this.importButton()}
            {this.addWorkerButton()}
          </Grid>
        </Grid>
        {this.table()}
      </div>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4" color="primary" className={classes.title}>
          Worker List
        </Typography>
      </Fragment>
    );
  }

  importButton() {
    const { classes } = this.props;

    return (
      <Button className={classes.button} variant="outlined" color="secondary">
        Import CSV
      </Button>
    );
  }

  addWorkerButton() {
    const { classes, updateWorker } = this.props;

    return (
      <Button
        className={classes.button}
        variant="outlined"
        color="secondary"
        component={Link}
        to="/workflows/worker-management/verify-worker"
        onClick={() => updateWorker({})}
      >
        Add Worker
      </Button>
    );
  }

  table() {
    const { classes, workers } = this.props;

    return (
      <div className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workers.map(n => (
              <TableRow key={n._id}>
                <TableCell>{n.name}</TableCell>
                <TableCell>{n.phone}</TableCell>
                <TableCell>{this.getLabel(n.status)}</TableCell>
                <TableCell>
                  {n.status > 0 && this.viewProfileButton(n)}
                  {n.status === 0 && this.sendSMSButton(n)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  sendSMSButton(workerObject) {
    const { updateWorker } = this.props;
    return (
      <Button
        variant="text"
        color="primary"
        component={Link}
        to="/workflows/worker-management/verify-worker"
        onClick={() => updateWorker(workerObject)}
      >
        Send Verification
      </Button>
    );
  }

  viewProfileButton(workerObject) {
    const { updateWorker } = this.props;
    return (
      <Button
        variant="text"
        color="primary"
        component={Link}
        to="/workflows/worker-management/profile"
        onClick={() => updateWorker(workerObject)}
      >
        View Profile
      </Button>
    );
  }

  getLabel = status => {
    if (status < 0) {
      return "Rejected";
    }

    if (status > 0) {
      return "Verified";
    }

    return "Pending";
  };
}

const mapStateToProps = state => {
  return {
    workers: state.gigWorker.workers
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateWorker: gigWorkerActions.updateWorker
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ListWorkers));
