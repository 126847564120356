import React from "react";
import { Typography, Button, Toolbar, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ListWorkflows from "../workflows/ListWorkflows";
import ListTemplates from "../templates/ListTemplates";
import ListPacks from "../packs/ListPacks";

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  button: {
    marginTop: 24
  }
});

class ListLayout extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Smart Contract Templates &amp; Workflows
        </Typography>
        <Typography variant="h5" color="secondary" gutterBottom>
          Simplify your enterprise's start with blockchain distributed
          applications with smart contract templates, workflows, and integrated
          packages. A few samples below.
        </Typography>
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <Toolbar disableGutters style={{ marginBottom: 20 }}>
            <Typography variant="h4" color="secondary" style={{ flexGrow: 1 }}>
              Modular Templates
            </Typography>
            <Hidden xsDown>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to={"/templates/templates"}
              >
                More Templates
              </Button>
            </Hidden>
          </Toolbar>
          <ListTemplates showcase={true} />
          <Hidden smUp>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={"/templates/templates"}
              fullWidth
              className={classes.button}
            >
              More Templates
            </Button>
          </Hidden>
        </div>
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <Toolbar disableGutters style={{ marginBottom: 20 }}>
            <Typography variant="h4" color="secondary" style={{ flexGrow: 1 }}>
              Quick-Start Workflows
            </Typography>
            <Hidden xsDown>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to={"/templates/workflows"}
              >
                More Workflows
              </Button>
            </Hidden>
          </Toolbar>
          <ListWorkflows showcase={true} />
          <Hidden smUp>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={"/templates/workflows"}
              fullWidth
              className={classes.button}
            >
              More Workflows
            </Button>
          </Hidden>
        </div>
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <Toolbar disableGutters style={{ marginBottom: 20 }}>
            <Typography variant="h4" color="secondary" style={{ flexGrow: 1 }}>
              Integration-Ready Packages
            </Typography>
            <Hidden xsDown>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to={"/templates/packs"}
              >
                More Packages
              </Button>
            </Hidden>
          </Toolbar>
          <ListPacks showcase={true} />
          <Hidden smUp>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={"/templates/packs"}
              fullWidth
              className={classes.button}
            >
              More Packages
            </Button>
          </Hidden>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ListLayout);
