import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// Attributes to modify
// https://material-ui.com/customization/default-theme/
const theme = {
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#ad1457",
      contrastText: "#fff"
    },
    secondary: {
      main: "#424242",
      contrastText: "#fff"
    },
    background: {
      default: "#fff"
    }
  },
  page: {
    padding: "40px 30px",
    box: {
      maxWidth: "940px",
      margin: "0 auto"
    },
    title: {
      marginBottom: 30
    }
  }
};

class Theme extends Component {
  render() {
    return (
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

export default Theme;
