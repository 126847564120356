import React from "react";
import { Button, CardActions } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DoneIcon from "@material-ui/icons/Done";
import Divider from "@material-ui/core/Divider";
import DappScreenshot from "../../images/BxS-SupplyChain-Dapp.gif";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    textAlign: "center",
    margin: "0 auto"
  }
});

class Cannabis extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Track Cannabis Shipments on Blockchain
        </Typography>
        <Typography variant="h5" color="secondary" gutterBottom>
          Supply-chain advancements are becoming increasingly important in the
          cannabis industry. Whether you're distributing recreational products
          to consumer markets or medical products to patients, physicians,
          hospitals, and governments you need a partner that can deliver
          end-to-end cannabis supply chain visibility in a secure, trust first
          system.
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
            <ListItemText primary="Securely trace cargo at each carrier step of the shipment" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
            <ListItemText primary="Reduce logistics coordination time from days to minutes" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
            <ListItemText primary="Consolidate immutable shared records across multiple shipping partners" />
          </ListItem>
        </List>
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={"../TrialsContact"}
          >
            START YOUR FREE TRIAL TODAY
          </Button>
        </CardActions>
        <Divider style={{ margin: "5% 0" }} />
        <Paper className={classes.root} elevation={1}>
          <img
            style={{ width: "100%" }}
            className="screenshot"
            alt="BLOCKxStudio Supply Chain Dapp"
            src={DappScreenshot}
          />
        </Paper>
        <Divider style={{ margin: "5% 0" }} />
        <Typography variant="h4" color="secondary" gutterBottom>
          Why our cannabis industry customers trust BLOCKxStudio.
        </Typography>
        <Typography variant="h6" gutterBottom>
          CPO of a Washington State CBD production facility:
        </Typography>
        <Typography variant="body1" gutterBottom>
          "BLOCKxStudio text alerts worked well for real-time awareness of where
          our product is from lab to distributor to retail."
        </Typography>
        <Typography variant="h6" gutterBottom>
          CEO of an international medical cannabis research organization:
        </Typography>
        <Typography variant="body1" gutterBottom>
          "BLOCKxStudio helped us think through the challenges of distributing
          medical cannabis to track it from source to patient."
        </Typography>
        <Divider style={{ margin: "5% 0" }} />
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={"../TrialsContact"}
          >
            START YOUR FREE TRIAL TODAY
          </Button>
        </CardActions>
      </div>
    );
  }
}

export default withStyles(styles)(Cannabis);
