import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  logoStudio: {
    color: theme.palette.primary.main
  },
  logoLink: {
    textDecoration: "none",
    fontWeight: "bold"
  },
  logoBLOCK: {
    color: "gray"
  }
});

class Logo extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Typography variant="h4">
        <Link to={"/"} className={classes.logoLink}>
          <span className={classes.logoBLOCK}>BLOCK</span>
          <span className={classes.logoStudio}>xStudio</span>
        </Link>
      </Typography>
    );
  }
}

Logo.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Logo));
