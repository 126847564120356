import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import Logo from "../shared/Logo2";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import Utils from "./Utils";
import { passwordResetsActions } from "../../actions";
import Loading from "../shared/Loading";

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  }
});

class ForgotPasswordPage extends Component {
  componentDidMount() {
    // Parse URL for code
    const code = Utils.parseLocationByKey(this.props.location, "code");

    // Ask API for the respective Password Reset with the code
    this.props.getPasswordResetByCode({ code });
  }

  render() {
    const { classes } = this.props;

    if (this.props.isGettingPasswordReset) {
      return <Loading text="Retrieving reset code..." />;
    }

    return (
      <div style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}>
        <Logo />
        <Paper style={{ padding: 30, marginTop: 30, marginBottom: 30 }}>
          <Typography variant="h4" gutterBottom>
            Reset Password
          </Typography>
          {!this.props.passwordReset && <ForgotPasswordForm />}
          {this.props.passwordReset && <ResetPasswordForm />}
        </Paper>
        <Typography gutterBottom>
          Remembered your password?{" "}
          <Link className={classes.link} to={"/login"}>
            Go back to Login
          </Link>
        </Typography>
        <Typography gutterBottom>
          Interested in joining BLOCKxStudio?{" "}
          <Link className={classes.link} to={"/signup"}>
            Request an Invite
          </Link>
        </Typography>
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    location: state.router.location,
    isGettingPasswordReset: state.passwordResets.isGettingPasswordReset,
    passwordReset: state.passwordResets.passwordReset
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPasswordResetByCode: passwordResetsActions.getPasswordResetByCode
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ForgotPasswordPage));
