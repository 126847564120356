import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";

let id = 0;
function createData(program, progress, eligibility) {
  id += 1;
  return { id, program, progress, eligibility };
}

const data = [
  createData("Housing Assistance", 0.25, false),
  createData("Career Training", 0.95, true),
  createData("Health Benefits", 0.8, true)
];

const styles = {
  root: {
    margin: "20px 0px",
    textAlign: "center"
  },
  title: {},
  table: {
    maxWidth: 600,
    margin: "0 auto",
    overflowX: "auto"
  }
};

class Programs extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.title()}
        {this.table()}
      </div>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Typography variant="h6" className={classes.title}>
        Program Eligibility
      </Typography>
    );
  }

  table() {
    const { classes } = this.props;

    return (
      <div className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Program</TableCell>
              <TableCell>Progress</TableCell>
              <TableCell numeric>Eligibility</TableCell>
              <TableCell numeric>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(n => {
              return (
                <TableRow key={n.id}>
                  <TableCell component="th" scope="row">
                    {n.program}
                  </TableCell>
                  <TableCell>
                    <LinearProgress
                      variant="determinate"
                      value={n.progress * 100}
                    />
                  </TableCell>
                  <TableCell numeric>
                    {n.eligibility ? (
                      <CheckCircle color="primary" />
                    ) : (
                      <Cancel color="secondary" />
                    )}
                  </TableCell>
                  <TableCell numeric>
                    {n.eligibility && (
                      <Button
                        variant="outlined"
                        color="primary"
                        // component={Link}
                        // to="/workflows/gig-worker/3"
                      >
                        Apply
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(Programs);
