import React from "react";
import { Button, Typography, CardActions } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    textAlign: "center",
    margin: "0 auto"
  }
});

class PrivateSessions extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Stuck on what to do next?
        </Typography>
        <Typography variant="h5" color="secondary" gutterBottom>
          BLOCKxStudio 15 minute engineering, or design, or finance private
          sessions available for $100 BTC or ETH.
        </Typography>
        <Divider style={{ margin: "5% 0" }} />
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            href="https://commerce.coinbase.com/checkout/f2a0beec-c28c-412a-97ba-917d0e7332f4"
          >
            Pay with Coinbase
          </Button>
        </CardActions>
        <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807" />
      </div>
    );
  }
}

export default withStyles(styles)(PrivateSessions);
