// Types
export const snackbarConstants = {
  OPEN_SNACKBAR: "OPEN_SNACKBAR",
  CLEAR_SNACKBAR: "CLEAR_SNACKBAR"
};

// Creators
export const snackbarActions = {
  open,
  clear
};

// Implementations
function open({ message = "", variant = "" } = {}) {
  return dispatch => {
    dispatch(success(message, variant));
  };

  function success(message, variant) {
    return { type: snackbarConstants.OPEN_SNACKBAR, message, variant };
  }
}

function clear() {
  return dispatch => {
    dispatch(success());
  };

  function success() {
    return { type: snackbarConstants.CLEAR_SNACKBAR };
  }
}
