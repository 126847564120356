import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";

import { authReducer } from "./authReducer";
import { usersReducer } from "./usersReducer";
import { organizationsReducer } from "./organizationsReducer";
import { templatesReducer } from "./templatesReducer";
import { web3Reducer } from "./web3Reducer";
import { workflowsReducer } from "./workflowsReducer";
import { packsReducer } from "./packsReducer";
import { gigWorkerReducer } from "./gigWorkerReducer";
import { invitesReducer } from "./invitesReducer";
import { snackbarReducer } from "./snackbarReducer";
import { passwordResetsReducer } from "./passwordResetsReducer";

const rootReducer = combineReducers({
  router: routerReducer,
  snackbar: snackbarReducer,
  auth: authReducer,
  users: usersReducer,
  organizations: organizationsReducer,
  templates: templatesReducer,
  web3: web3Reducer,
  workflows: workflowsReducer,
  packs: packsReducer,
  gigWorker: gigWorkerReducer,
  invites: invitesReducer,
  passwordResets: passwordResetsReducer
});

export default rootReducer;
