import React, { Component } from "react";
import qs from "qs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, Paper, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { templatesActions } from "../../actions";

const styles = {};

class TemplatePurchase extends Component {
  renderWorkflowButton() {
    const { authUser } = this.props;
    if (!authUser) {
      const ref = qs.stringify({ ref: "/workflow/search" });
      return (
        <Button
          style={{ marginTop: 20 }}
          variant="contained"
          color="primary"
          component={Link}
          to={`/login?${ref}`}
        >
          Gig Worker Workflow
        </Button>
      );
    }

    return (
      <Button
        style={{ marginTop: 20 }}
        variant="contained"
        color="primary"
        component={Link}
        to="/workflow/search"
      >
        Gig Worker Workflow
      </Button>
    );
  }

  render() {
    const { template, authUser } = this.props;

    if (!template) {
      return (
        <Typography gutterBottom>No template details available.</Typography>
      );
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5" gutterBottom align="center">
              Template Details
            </Typography>
            <Typography gutterBottom>{template.description}</Typography>
            <Typography gutterBottom>
              Quickly deploy blockchain distributed application solutions with
              this robust ready-to-deploy Solidity code template.
            </Typography>
            <div style={{ paddingTop: 20, textAlign: "center" }}>
              {!authUser && (
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/contact"
                >
                  Get a Demo
                </Button>
              )}
              {authUser && (
                <Button variant="contained" color="primary">
                  Purchase Template
                </Button>
              )}
            </div>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Paper style={{ padding: 20, textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              Embed Code
            </Typography>
            <Typography gutterBottom>
              Once your purchase has been completed your embed code will be
              displayed here.
            </Typography>

            {template.name === "Gig Worker Onboarding" &&
              this.renderWorkflowButton()}
            <Paper style={{ padding: 20 }} elevation={0}>
              <Typography color="textSecondary" gutterBottom>
                Transaction processing.
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                This may take more than 30 mins.
              </Typography>
              <LinearProgress color="primary" />
            </Paper>
          </Paper>
        </Grid> */}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    template: state.templates.template,
    isGettingTemplate: state.templates.isGettingTemplate,
    authUser: state.auth.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTemplate: templatesActions.getTemplate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TemplatePurchase));
