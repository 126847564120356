import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Card, Typography, Grid, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../shared/Loading";
import { workflowsActions } from "../../actions";

const styles = {
  title: {
    marginBottom: 16,
    fontSize: 14
  },
  link: {
    textDecoration: "none"
  },
  card: {
    height: "100%"
  }
};

class ListWorkflows extends React.Component {
  componentDidMount() {
    this.props.getWorkflows({
      purchasedBy: this.props.authUser.organization._id
    });
  }

  render() {
    const { isGettingWorkflows, workflows } = this.props;

    if (isGettingWorkflows) {
      return <Loading text="Loading Your Workflows..." />;
    }

    if (!workflows) {
      return <Typography>You do not have any workflows.</Typography>;
    }

    return (
      <Grid container spacing={16} alignItems="stretch">
        {workflows.map(workflow => this.card(workflow))}
      </Grid>
    );
  }

  card(workflow) {
    const { classes } = this.props;

    if (workflow._id === "5b6e197274886a68e922f36a") {
      return;
    }

    const getLink = id => {
      const links = {
        "5b6e188774886a68e922f369": "/workflows/supply-chain",
        "5b6e197274886a68e922f36a": "/workflows/gig-worker",
        "5ba01d20910156f8ef292c40": "/workflows/worker-management",
        "5ba04366910156f8ef29cc4c": "/workflows/worker-profile"
      };
      return links[id] || "/studio/dashboard";
    };

    return (
      <Grid item xs={12} md={4} key={workflow._id}>
        <Link to={getLink(workflow._id)} className={classes.link}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                {workflow.name}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.auth.user,
  isGettingWorkflows: state.workflows.isGettingWorkflows,
  workflows: state.workflows.workflows
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWorkflows: workflowsActions.getWorkflows
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ListWorkflows));
