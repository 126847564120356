import React from "react";
import PropTypes from "prop-types";
import image_services from "../../images/image_services.svg";
import image_templates from "../../images/image_templates.svg";
import image_provisioning from "../../images/image_provisioning.svg";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ServiceCard from "./ServiceCard";

const styles = {
  root: {
    margin: "40px 0px",
    padding: 0
  },
  grid: {
    maxWidth: 1040,
    margin: "0 auto"
  }
};

class Services extends React.Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  state = {
    services: [
      {
        name: "Modular Smart Contract Templates",
        description:
          "Rapidly deploy blockchain distributed application solutions by combining robust, ready-to-use templates.",
        button: "View Templates",
        path: "/templates",
        image: image_services
      },
      {
        name: "Premium Engineering Services",
        description:
          "Blockchain engineers available for complex integration architecture and hybrid applications.",
        button: "View Services",
        path: "/services",
        image: image_templates
      },
      {
        name: "Private Blockchain Provisioning",
        description:
          "Maximize deployment speed, flexibility and security by launching your solution on a managed private blockchain network infrastructure.",
        button: "Contact Us",
        path: "/contact",
        image: image_provisioning
      }
    ]
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          alignItems="stretch"
          justify="center"
          className={classes.grid}
        >
          {this.state.services.map((service, i) => (
            <ServiceCard key={i} data={service} />
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Services);
