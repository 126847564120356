import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { getRoleById } from "../../constants/Roles";
import RequestList from "./RequestList";

const styles = {
  list: {
    marginTop: 40,
    overflowX: "scroll"
  }
};

class RequestsSection extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    const userRole = getRoleById(this.props.internalRole);

    if (!userRole) {
      return (
        <Typography variant="subtitle1">
          Error: You do not have a valid internal team role.
        </Typography>
      );
    }

    return (
      <Fragment>
        <Typography variant="h4" gutterBottom>
          Manage Invite Requests
        </Typography>
        {!userRole.canInvite && (
          <Typography variant="subtitle1">
            As a {userRole.name}, you cannot approve invite requests.
          </Typography>
        )}
        {userRole.canInvite && (
          <Fragment>
            <Typography variant="subtitle1" gutterBottom>
              You can approve requests for users to join BLOCKxStudio. Select a
              role for them and then hit "Approve", which will send an email to
              them to join. If you do not approve them, then hit "Decline" to
              remove their request.
            </Typography>
            <RequestList className={classes.list} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  internalRole: state.auth.user.internalRole
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestsSection));
