import { passwordResetsConstants } from "../actions";

const PASSWORD_RESETS_INITIAL = {
  passwordReset: null,
  passwordResets: null,
  isGettingPasswordReset: false,
  isGettingPasswordResets: false,
  isDeletingPasswordReset: false,
  isCreatingPasswordReset: false,
  isResetingPassword: false
};

export const passwordResetsReducer = (
  state = PASSWORD_RESETS_INITIAL,
  action
) => {
  switch (action.type) {
    case passwordResetsConstants.GET_PASSWORD_RESETS_REQUEST:
      return {
        ...state,
        isGettingPasswordResets: true
      };
    case passwordResetsConstants.GET_PASSWORD_RESETS_SUCCESS:
      return {
        ...state,
        passwordResets: action.payload,
        isGettingPasswordResets: false
      };
    case passwordResetsConstants.GET_PASSWORD_RESETS_FAILURE:
      return {
        ...state,
        passwordResets: null,
        isGettingPasswordResets: false
      };

    case passwordResetsConstants.GET_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        isGettingPasswordReset: true
      };
    case passwordResetsConstants.GET_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordReset: action.payload,
        isGettingPasswordReset: false
      };
    case passwordResetsConstants.GET_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        passwordReset: null,
        isGettingPasswordReset: false
      };

    case passwordResetsConstants.DELETE_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        isDeletingPasswordReset: true
      };
    case passwordResetsConstants.DELETE_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordReset: action.payload,
        isDeletingPasswordReset: false
      };
    case passwordResetsConstants.DELETE_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        passwordReset: null,
        isDeletingPasswordReset: false
      };

    case passwordResetsConstants.CREATE_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        isCreatingPasswordReset: true
      };
    case passwordResetsConstants.CREATE_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isCreatingPasswordReset: false
      };
    case passwordResetsConstants.CREATE_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        isCreatingPasswordReset: false
      };

    case passwordResetsConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetingPassword: true
      };
    case passwordResetsConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetingPassword: false
      };
    case passwordResetsConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isResetingPassword: false
      };

    default:
      return state;
  }
};
