import axios from "axios";
import { BXSAPIURL } from "../constants";
import { snackbarActions } from "./snackbarActions";
import { push } from "react-router-redux";

// Types
export const passwordResetsConstants = {
  GET_PASSWORD_RESETS_REQUEST: "GET_PASSWORD_RESETS_REQUEST",
  GET_PASSWORD_RESETS_SUCCESS: "GET_PASSWORD_RESETS_SUCCESS",
  GET_PASSWORD_RESETS_FAILURE: "GET_PASSWORD_RESETS_FAILURE",

  GET_PASSWORD_RESET_REQUEST: "GET_PASSWORD_RESET_REQUEST",
  GET_PASSWORD_RESET_SUCCESS: "GET_PASSWORD_RESET_SUCCESS",
  GET_PASSWORD_RESET_FAILURE: "GET_PASSWORD_RESET_FAILURE",

  DELETE_PASSWORD_RESET_REQUEST: "DELETE_PASSWORD_RESET_REQUEST",
  DELETE_PASSWORD_RESET_SUCCESS: "DELETE_PASSWORD_RESET_SUCCESS",
  DELETE_PASSWORD_RESET_FAILURE: "DELETE_PASSWORD_RESET_FAILURE",

  CREATE_PASSWORD_RESET_REQUEST: "CREATE_PASSWORD_RESET_REQUEST",
  CREATE_PASSWORD_RESET_SUCCESS: "CREATE_PASSWORD_RESET_SUCCESS",
  CREATE_PASSWORD_RESET_FAILURE: "CREATE_PASSWORD_RESET_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE"
};

// Creators
export const passwordResetsActions = {
  getPasswordReset,
  getPasswordResets,
  deletePasswordReset,
  getPasswordResetByCode,
  createPasswordReset,
  resetPassword
};

// Implementations
function getPasswordResets() {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: "/passwordresets",
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: passwordResetsConstants.GET_PASSWORD_RESETS_REQUEST };
  }
  function success(payload) {
    return {
      type: passwordResetsConstants.GET_PASSWORD_RESETS_SUCCESS,
      payload
    };
  }
  function failure() {
    return { type: passwordResetsConstants.GET_PASSWORD_RESETS_FAILURE };
  }
}

function getPasswordReset(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/passwordresets/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: passwordResetsConstants.GET_PASSWORD_RESET_REQUEST };
  }
  function success(payload) {
    return {
      type: passwordResetsConstants.GET_PASSWORD_RESET_SUCCESS,
      payload
    };
  }
  function failure() {
    return { type: passwordResetsConstants.GET_PASSWORD_RESET_FAILURE };
  }
}

function deletePasswordReset(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "delete",
      url: `/passwordresets/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: passwordResetsConstants.DELETE_PASSWORD_RESET_REQUEST };
  }
  function success(payload) {
    return {
      type: passwordResetsConstants.DELETE_PASSWORD_RESET_SUCCESS,
      payload
    };
  }
  function failure() {
    return { type: passwordResetsConstants.DELETE_PASSWORD_RESET_FAILURE };
  }
}

function getPasswordResetByCode({ code = "" } = {}) {
  return dispatch => {
    dispatch(request());

    if (!code) {
      dispatch(failure());
      return;
    }

    axios({
      method: "get",
      url: `/passwordresets?code=${code}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        // Need to convert the payload array into a single object
        if (!res.data.payload || res.data.payload.length < 1) {
          dispatch(success(null));
        } else {
          dispatch(success(res.data.payload[0]));
        }
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to get password reset by code.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: passwordResetsConstants.GET_PASSWORD_RESET_REQUEST };
  }
  function success(payload) {
    return {
      type: passwordResetsConstants.GET_PASSWORD_RESET_SUCCESS,
      payload
    };
  }
  function failure() {
    return { type: passwordResetsConstants.GET_PASSWORD_RESET_FAILURE };
  }
}

function createPasswordReset({ email = "" } = {}) {
  return dispatch => {
    dispatch(request());

    if (!email) {
      dispatch(failure());
      return;
    }

    axios({
      method: "post",
      url: "/passwordresets",
      baseURL: BXSAPIURL,
      data: { email: email }
    })
      .then(res => {
        dispatch(success());
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to complete request to reset password.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: passwordResetsConstants.CREATE_PASSWORD_RESET_REQUEST };
  }
  function success() {
    return {
      type: passwordResetsConstants.CREATE_PASSWORD_RESET_SUCCESS
    };
  }
  function failure() {
    return { type: passwordResetsConstants.CREATE_PASSWORD_RESET_FAILURE };
  }
}

function resetPassword({ email = "", password = "" } = {}) {
  return dispatch => {
    dispatch(request());

    if (!email || !password) {
      dispatch(failure());
      return;
    }

    axios({
      method: "post",
      url: "/users/updatepassword",
      baseURL: BXSAPIURL,
      data: { email, password }
    })
      .then(res => {
        dispatch(success());
        dispatch(push("login"));
        dispatch(
          snackbarActions.open({
            message: "Your password has been updated. Try logging in now",
            variant: "success"
          })
        );
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to complete request to reset password.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: passwordResetsConstants.RESET_PASSWORD_REQUEST };
  }
  function success() {
    return {
      type: passwordResetsConstants.RESET_PASSWORD_SUCCESS
    };
  }
  function failure() {
    return { type: passwordResetsConstants.RESET_PASSWORD_FAILURE };
  }
}
