import axios from "axios";
import { BXSAPIURL } from "../constants";
import { snackbarActions } from "./snackbarActions";

// Types
export const usersConstants = {
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE"
};

// Creators
export const usersActions = {
  getUser,
  getUsers,
  deleteUser
};

// Implementations
function getUsers() {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: "/users",
      baseURL: BXSAPIURL
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to load users.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: usersConstants.GET_USERS_REQUEST };
  }
  function success(payload) {
    return { type: usersConstants.GET_USERS_SUCCESS, payload };
  }
  function failure() {
    return { type: usersConstants.GET_USERS_FAILURE };
  }
}

function getUser(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/users/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to load user.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: usersConstants.GET_USER_REQUEST };
  }
  function success(payload) {
    return { type: usersConstants.GET_USER_SUCCESS, payload };
  }
  function failure() {
    return { type: usersConstants.GET_USER_FAILURE };
  }
}

function deleteUser(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "delete",
      url: `/users/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        dispatch(success(res.data.payload));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(
          snackbarActions.open({
            message: "Unable to delete user.",
            variant: "error"
          })
        );
      });
  };

  function request() {
    return { type: usersConstants.DELETE_USER_REQUEST };
  }
  function success(payload) {
    return { type: usersConstants.DELETE_USER_SUCCESS, payload };
  }
  function failure() {
    return { type: usersConstants.DELETE_USER_FAILURE };
  }
}
