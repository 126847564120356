import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = {
  root: {
    // padding: 30,
    margin: "20px 0px",
    textAlign: "center"
  },
  title: {},
  table: {
    maxWidth: 400,
    margin: "0 auto"
    // padding: "20px 20px 60px"
  },
  button: {
    margin: "0px auto 20px"
  }
};

let id = 0;
function createData(organization, hours) {
  id += 1;
  return { id, organization, hours };
}

const data = [
  createData("Uber", 22.5),
  createData("Lyft", 51.2),
  createData("Rover", 45.8),
  createData("Casa Latina", 76.0)
];

class Aggregate extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.title()}
        {this.table()}
      </div>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Typography variant="h6" className={classes.title}>
        Aggregated Hours
      </Typography>
    );
  }

  table() {
    const { classes } = this.props;

    return (
      <div className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Organization</TableCell>
              <TableCell numeric>Hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(n => {
              return (
                <TableRow key={n.id}>
                  <TableCell component="th" scope="row">
                    {n.organization}
                  </TableCell>
                  <TableCell numeric>{n.hours}</TableCell>
                </TableRow>
              );
            })}
            <TableRow key="totalHours">
              <TableCell>
                <b>TOTAL HOURS</b>
              </TableCell>
              <TableCell numeric>195.5</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  button() {
    const { classes } = this.props;

    return (
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        component={Link}
        to="/workflows/gig-worker/3"
      >
        Find Eligible Programs
      </Button>
    );
  }
}

export default withStyles(styles)(Aggregate);
