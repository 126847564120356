import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tab, Tabs, Typography } from "@material-ui/core";
import Aggregate from "./Aggregate";
import Programs from "./Programs";
import Profile from "./Profile";

const styles = {
  root: {
    padding: 30,
    margin: "40px 20px",
    textAlign: "center"
  }
};

class GigWorkerProfile extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  state = {
    tabValue: 0
  };

  render() {
    const { classes } = this.props;
    const { tabValue } = this.state;

    return (
      <div className={classes.root}>
        {this.title()}
        {this.tabs()}
        {tabValue === 0 && <Profile />}
        {tabValue === 1 && <Aggregate />}
        {tabValue === 2 && <Programs />}
      </div>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4" color="primary" className={classes.title}>
          Worker Profile
        </Typography>
        {/* <Typography variant="subtitle1">Gig Worker View</Typography> */}
      </Fragment>
    );
  }

  handleChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  tabs() {
    const { tabValue } = this.state;

    return (
      <Tabs
        value={tabValue}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="primary"
        style={{ marginTop: 20, marginBottom: 20 }}
        centered
      >
        <Tab value={0} label="Profile" />
        <Tab value={1} label="Hours" />
        <Tab value={2} label="Benefits" />
      </Tabs>
    );
  }
}

export default withStyles(styles)(GigWorkerProfile);
