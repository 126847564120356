import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../shared/Loading";
import CardGrid from "../shared/CardGrid";
import { templatesActions } from "../../actions";

const styles = {};

class ListTemplates extends React.Component {
  static protoTypes = {
    showcase: PropTypes.bool
  };

  static defaultProps = {
    showcase: false
  };

  componentDidMount() {
    this.props.getTemplates();
  }

  render() {
    const { templates, isGettingTemplates } = this.props;

    if (isGettingTemplates) {
      return <Loading text="Loading Templates" />;
    }

    if (!templates) {
      return <Typography variant="h6">No Templates Available</Typography>;
    }

    return (
      <CardGrid
        objects={templates}
        link="/templates/templates"
        linkText="Learn More"
        appendId={true}
        type="TEMPLATE"
        filterObjects={this.filter}
      />
    );
  }

  filter = objects => {
    const { showcase, user } = this.props;
    let filteredObjects = objects;

    // Only show approved templates
    filteredObjects = filteredObjects.filter(object => object.isApproved);

    // Show showcased templates
    if (showcase) {
      filteredObjects = filteredObjects.filter(object => object.showcase);
    }

    // If not logged in, only show BLOCKxStudio-created templates
    if (!user) {
      filteredObjects = filteredObjects.filter(
        object => object.createdBy === "BLOCKxStudio"
      );
    }

    // If logged in, only show BLOCKxStudio-created and user-created templates
    if (user) {
      filteredObjects = filteredObjects.filter(
        object =>
          object.createdBy === "BLOCKxStudio" || object.createdBy === user.email
      );
    }

    return filteredObjects;
  };
}

const mapStateToProps = state => {
  return {
    templates: state.templates.templates,
    isGettingTemplates: state.templates.isGettingTemplates,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTemplates: templatesActions.getTemplates
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ListTemplates));
