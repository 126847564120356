import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";
import { passwordResetsActions } from "../../actions";

const styles = theme => ({});

class ResetPasswordForm extends Component {
  render() {
    return (
      <form>
        <Typography gutterBottom>
          Enter a new password for {this.props.passwordReset.email}
        </Typography>
        <TextField
          key="password"
          id="password"
          label="New Password"
          autoComplete="password"
          type="password"
          onChange={event =>
            this.setState({
              password: event.target.value
            })
          }
          fullWidth
          margin="normal"
          required
        />
        <Button
          type="submit"
          color="primary"
          style={{ marginTop: 20 }}
          variant="contained"
          onClick={event => {
            event.preventDefault();
            this.props.resetPassword({
              email: this.props.passwordReset.email,
              password: this.state.password
            });
          }}
        >
          Update Password
        </Button>
      </form>
    );
  }
}

ResetPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    passwordReset: state.passwordResets.passwordReset
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetPassword: passwordResetsActions.resetPassword
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResetPasswordForm));
