import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import { authActions } from "../../actions";
import Loading from "../shared/Loading";
import Utils from "./Utils";

const styles = theme => ({});

class LogInForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {}
    };
  }

  handleFormSubmit = event => {
    event.preventDefault();
    const { login } = this.props;

    const parsedLocation = Utils.parseLocation(this.props.router.location);

    login({
      ...this.state.formState,
      pushOnSuccess: parsedLocation.ref,
      isExternalURL: Utils.stringToBoolean(parsedLocation.external),
      returnToken: Utils.stringToBoolean(parsedLocation.returnToken)
    });
  };

  render() {
    const { isLoggingIn } = this.props;

    if (isLoggingIn) {
      return (
        <div style={{ padding: 20 }}>
          <Loading text="Logging In" />
        </div>
      );
    }

    return (
      <form onSubmit={this.handleFormSubmit}>
        <TextField
          key="email"
          id="email"
          label="Email"
          autoComplete="email"
          type="text"
          onChange={event =>
            this.setState({
              formState: {
                ...this.state.formState,
                email: event.target.value
              }
            })
          }
          fullWidth
          margin="normal"
          required
        />
        <TextField
          key="password"
          id="password"
          label="Password"
          autoComplete="password"
          type="password"
          onChange={event =>
            this.setState({
              formState: {
                ...this.state.formState,
                password: event.target.value
              }
            })
          }
          fullWidth
          margin="normal"
          required
        />
        <Button
          type="submit"
          color="primary"
          style={{ marginTop: 20 }}
          variant="contained"
        >
          Log In
        </Button>
      </form>
    );
  }
}

LogInForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    router: state.router
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login: authActions.login
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LogInForm));
