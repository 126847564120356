import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, CircularProgress } from "@material-ui/core";
import TemplateTabs from "./TemplateTabs";
import TemplatePurchase from "./TemplatePurchase";
import TemplateDemo from "./TemplateDemo";
import { templatesActions } from "../../actions";
import AuthGate from "../shared/AuthGate";

const styles = {
  root: {
    textAlign: "left",
    maxWidth: 940,
    margin: "0 auto"
  }
};

class ViewTemplate extends Component {
  componentDidMount() {
    this.props.getTemplate(this.props.match.params.id);
  }

  render() {
    const { template, isGettingTemplate, authUser, classes } = this.props;

    if (isGettingTemplate) {
      return (
        <div>
          <CircularProgress />
          <Typography variant="h5">Loading Template</Typography>
        </div>
      );
    }

    if (!template) {
      return <Typography variant="h5">Template Not Found</Typography>;
    }

    return (
      <Paper className={classes.root} elevation={0}>
        <Typography variant="subtitle1" color="secondary" gutterBottom>
          TEMPLATES
        </Typography>
        <Typography variant="h3" color="primary" gutterBottom>
          {template.name}
        </Typography>
        <TemplateTabs template={template} />
        <Switch>
          <Route
            exact
            path="/templates/templates/:id"
            render={() => <TemplatePurchase />}
          />
          <Route
            exact
            path="/templates/templates/:id/demo"
            render={() =>
              authUser ? (
                <TemplateDemo template={template} />
              ) : (
                <AuthGate returnBack />
              )
            }
          />
        </Switch>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    template: state.templates.template,
    isGettingTemplate: state.templates.isGettingTemplate,
    authUser: state.auth.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTemplate: templatesActions.getTemplate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ViewTemplate));
