import { workflowsConstants } from "../actions";

const WORKFLOWS_INITIAL = {
  workflow: null,
  workflows: null,
  isGettingWorkflow: false,
  isGettingWorkflows: false
};

export const workflowsReducer = (state = WORKFLOWS_INITIAL, action) => {
  switch (action.type) {
    case workflowsConstants.GET_WORKFLOW_REQUEST:
      return {
        ...state,
        isGettingWorkflow: true
      };
    case workflowsConstants.GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        workflow: action.payload,
        isGettingWorkflow: false
      };
    case workflowsConstants.GET_WORKFLOW_FAILURE:
      return {
        ...state,
        workflow: null,
        isGettingWorkflow: false
      };

    case workflowsConstants.GET_WORKFLOWS_REQUEST:
      return {
        ...state,
        isGettingWorkflows: true
      };
    case workflowsConstants.GET_WORKFLOWS_SUCCESS:
      return {
        ...state,
        workflows: action.payload,
        isGettingWorkflows: false
      };
    case workflowsConstants.GET_WORKFLOWS_FAILURE:
      return {
        ...state,
        workflows: null,
        isGettingWorkflows: false
      };

    default:
      return state;
  }
};
