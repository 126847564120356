import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { Link } from "react-router-dom";
import location_img from "../../../../images/gig-details-location.png";

const styles = {
  root: {
    padding: 30,
    margin: "40px 20px",
    textAlign: "center"
  },
  title: {},
  table: {
    // maxWidth: 400,
    margin: "0 auto",
    overflowX: "auto",
    padding: "20px"
  },
  button: {
    marginTop: 30
  }
};

class GigDetails extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.title()}
        {this.info()}
        {this.backToListButton()}
      </div>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography
          variant="h4"
          color="primary"
          className={classes.title}
        >
          Job Details
        </Typography>
      </Fragment>
    );
  }

  info() {
    const { classes, gig } = this.props;

    return (
      <Grid container className={classes.grid} spacing={16}>
        <Grid item xs={12} sm={8}>
          <List component="nav">
            <ListItem>
              <ListItemText secondary={gig.dateTime} primary="Date/Time" />
            </ListItem>
            <ListItem>
              <ListItemText secondary={gig.location} primary="Location" />
            </ListItem>
            <ListItem>
              <ListItemText secondary={gig.company} primary="Company" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Task" secondary={gig.task} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Rate" secondary={gig.rate} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Details" secondary={gig.details} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={4}>
          <img
            src={location_img}
            alt="Job Location on Map"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    );
  }

  backToListButton() {
    const { classes } = this.props;

    return (
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        component={Link}
        to="/workflows/worker-management/gigs"
      >
        Back to Jobs List
      </Button>
    );
  }
}

const mapStateToProps = state => {
  return {
    gig: state.gigWorker.gig
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GigDetails));
