import axios from "axios";
import { BXSAPIURL } from "../constants";

// Types
export const packsConstants = {
  GET_PACKS_REQUEST: "GET_PACKS_REQUEST",
  GET_PACKS_SUCCESS: "GET_PACKS_SUCCESS",
  GET_PACKS_FAILURE: "GET_PACKS_FAILURE",

  GET_PACK_REQUEST: "GET_PACK_REQUEST",
  GET_PACK_SUCCESS: "GET_PACK_SUCCESS",
  GET_PACK_FAILURE: "GET_PACK_FAILURE"
};

// Creators
export const packsActions = {
  getPack,
  getPacks
};

// Implementations
function getPacks() {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: "/packs",
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: packsConstants.GET_PACKS_REQUEST };
  }
  function success(payload) {
    return { type: packsConstants.GET_PACKS_SUCCESS, payload };
  }
  function failure() {
    return { type: packsConstants.GET_PACKS_FAILURE };
  }
}

function getPack(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/packs/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: packsConstants.GET_PACK_REQUEST };
  }
  function success(payload) {
    return { type: packsConstants.GET_PACK_SUCCESS, payload };
  }
  function failure() {
    return { type: packsConstants.GET_PACK_FAILURE };
  }
}
