import axios from "axios";
import qs from "qs";
import { BXSAPIURL } from "../constants";

// Types
export const workflowsConstants = {
  GET_WORKFLOWS_REQUEST: "GET_WORKFLOWS_REQUEST",
  GET_WORKFLOWS_SUCCESS: "GET_WORKFLOWS_SUCCESS",
  GET_WORKFLOWS_FAILURE: "GET_WORKFLOWS_FAILURE",

  GET_WORKFLOW_REQUEST: "GET_WORKFLOW_REQUEST",
  GET_WORKFLOW_SUCCESS: "GET_WORKFLOW_SUCCESS",
  GET_WORKFLOW_FAILURE: "GET_WORKFLOW_FAILURE"
};

// Creators
export const workflowsActions = {
  getWorkflow,
  getWorkflows
};

// Implementations
function getWorkflows(q = {}) {
  const query = qs.stringify(q);
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/workflows?${query}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: workflowsConstants.GET_WORKFLOWS_REQUEST };
  }
  function success(payload) {
    return { type: workflowsConstants.GET_WORKFLOWS_SUCCESS, payload };
  }
  function failure() {
    return { type: workflowsConstants.GET_WORKFLOWS_FAILURE };
  }
}

function getWorkflow(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/workflows/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: workflowsConstants.GET_WORKFLOW_REQUEST };
  }
  function success(payload) {
    return { type: workflowsConstants.GET_WORKFLOW_SUCCESS, payload };
  }
  function failure() {
    return { type: workflowsConstants.GET_WORKFLOW_FAILURE };
  }
}
