import Web3 from "web3";
import { TESTNETURL } from "../constants";

// Types
export const web3Constants = {
  INIT_WEB3_SUCCESS: "INIT_WEB3_SUCCESS",
  INIT_WEB3_FAILURE: "INIT_WEB3_FAILURE"
};

// Creators
export const web3Actions = {
  initWeb3
};

// Implementations
function initWeb3() {
  return dispatch => {
    let web3 = new Web3(TESTNETURL);
    dispatch(success(web3));
  };

  function success(web3Instance) {
    return { type: web3Constants.INIT_WEB3_SUCCESS, web3Instance };
  }
  // function failure() {
  //   return { type: web3Constants.INIT_WEB3_FAILURE };
  // }
}
