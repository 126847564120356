import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import Loading from "../shared/Loading";
import RequestCard from "./RequestCard";
import { invitesActions } from "../../actions";

const styles = {};

class RequestList extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  state = {
    open: false,
    selectedRequest: {},
    selectedRole: null
  };

  rehydrateState() {
    this.props.getRequests();
  }

  componentDidMount() {
    this.rehydrateState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isDeletingRequest) {
      this.rehydrateState();
    }
  }

  render() {
    const { requests, isGettingRequests, isDeletingRequest } = this.props;

    if (isGettingRequests) {
      return <Loading text="Loading Requests..." />;
    }

    if (isDeletingRequest) {
      return <Loading text="Updating Requests..." />;
    }

    if (!requests || requests.length < 1) {
      return (
        <div className={this.props.className}>
          <Typography variant="h5" gutterBottom>
            Requests List
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            No Requests to List
          </Typography>
        </div>
      );
    }

    return (
      <Fragment>
        <div className={this.props.className}>
          <Typography variant="h5" gutterBottom>
            Requests List
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.map(request => {
                return (
                  <TableRow
                    hover
                    key={request._id}
                    onClick={() =>
                      this.setState({
                        open: true,
                        selectedRequest: request
                      })
                    }
                  >
                    <TableCell>
                      {moment(request.timestamp).format("LLL")}
                    </TableCell>
                    <TableCell>{request.name}</TableCell>
                    <TableCell>{request.email}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <RequestCard
          open={this.state.open}
          handleClose={() => this.setState({ open: false })}
          handleApprove={role => {
            this.setState({ open: false });
            console.log("Approve clicked", role);
            this.props.sendInvite({
              email: this.state.selectedRequest.email,
              role: role
            });
            this.props.deleteRequest(this.state.selectedRequest._id);
          }}
          handleDecline={() => {
            this.setState({ open: false });
            this.props.deleteRequest(this.state.selectedRequest._id);
          }}
          request={this.state.selectedRequest}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    requests: state.invites.requests,
    isGettingRequests: state.invites.isGettingRequests,
    isDeletingRequest: state.invites.isDeletingRequest
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRequests: invitesActions.getRequests,
      deleteRequest: invitesActions.deleteRequest,
      sendInvite: invitesActions.sendInvite
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestList));
