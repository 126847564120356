import Cookies from "universal-cookie";
export const cookies = new Cookies();

export const BXSAPIURL = "https://api.blockxstudio.com/1.0/";
export const TESTNETURL = "https://testnet.blockxstudio.com/";
export const systemWallet = "0xe19a6c02fc51ffb68702a40e0f982d45df18f89f";

// Set this to false when working on Password Reset feature.
// Remove this and all imports of this constant when finished with feature.
export const PAUSE_RESET_PASSWORD_WIP = false;
