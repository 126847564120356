import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  CardActions,
  Button,
  Card,
  CardContent
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";

let dataId = 0;
function createData(program, progress, eligibility) {
  dataId += 1;
  return { id: dataId, program, progress, eligibility };
}

const data = [
  createData("Health Benefits", 0.8, true),
  createData("Career Training", 0.4, false),
  createData("Fee-Free Banking", 0.25, false),
  createData("Housing Assistance", 0.3, false),
  createData("Discounts to YMCA", 0.2, false),
  createData("Coupon for Uber Eats", 0.9, true)
];

let badgeId = 0;
function createBadgeData(picture, title) {
  badgeId += 1;
  return { id: badgeId, picture, title };
}

const badgeData = [
  createBadgeData("picture a", "Free Starbucks Drink"),
  createBadgeData("picture b", "Discount"),
  createBadgeData("picture c", "Coupon"),
  createBadgeData("picture d", "Gift")
];

const styles = {
  root: {
    margin: "20px 0px",
    textAlign: "center"
  },
  title: {},
  table: {
    maxWidth: 600,
    margin: "30px auto",
    overflowX: "auto"
  },
  media: {
    height: 400
  }
};

class Programs extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.title()}
        {this.table()}
        {this.badgesTitle()}
        {this.badges()}
      </div>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Typography variant="h5" className={classes.title}>
        Program Eligibility
      </Typography>
    );
  }

  table() {
    const { classes } = this.props;

    return (
      <div className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Program</TableCell>
              <TableCell>Progress</TableCell>
              <TableCell numeric>Eligibility</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(n => {
              return (
                <TableRow key={n.id}>
                  <TableCell component="th" scope="row">
                    {n.program}
                  </TableCell>
                  <TableCell>
                    <LinearProgress
                      variant="determinate"
                      value={n.progress * 100}
                    />
                  </TableCell>
                  <TableCell numeric>
                    {n.eligibility ? (
                      <CheckCircle color="primary" />
                    ) : (
                      <Cancel color="secondary" />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  badgesTitle() {
    const { classes } = this.props;

    return (
      <Typography variant="h5" className={classes.title}>
        Badges
      </Typography>
    );
  }

  badges() {
    const { classes } = this.props;

    return (
      <div className={classes.table}>
        {badgeData.map(n => {
          return (
            <Card className={classes.card} key={n.id}>
              {/* <CardMedia
                className={classes.media}
                image={badgePicture}
                title="Badge"
              /> */}
              <CardContent>
                <Typography align="left" variant="h5">
                  {n.title}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  className={classes.button}
                  variant="text"
                  color="primary"
                >
                  Claim Offer
                </Button>
                <Button to="/workflows/worker-profile/gig-details" size="small">
                  View Details
                </Button>
              </CardActions>
            </Card>
          );
        })}
      </div>
    );
  }
}

export default withStyles(styles)(Programs);
