import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Typography,
  Paper,
  Grid,
  Button,
  LinearProgress
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { systemWallet } from "../../constants";
import FormGenerator from "./FormGenerator";

class TemplateDemo extends Component {
  static protoTypes = {
    template: PropTypes.object.isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      ContractInstance: new props.web3.eth.Contract(
        props.template.ABI,
        props.template.demoAddress,
        {
          from: systemWallet,
          gasPrice: "0"
        }
      ),
      contractData: null,
      transactionHash: null,
      contractTransaction: null,
      transactionIsPending: false,
      transactionError: null
    };
  }

  handleFormSubmit = ({ values }) => {
    const { ContractInstance } = this.state;

    // Temporary Workaround - Remove following line when solved
    //   Issue: Out of gas VM exception error
    //   Workaround: Convert values to smaller byte values
    const tempValues = values.map((value, i) => `${i}`);

    ContractInstance.methods
      .setData(...tempValues)
      .send({
        from: systemWallet,
        gasPrice: "0"
      })
      .on("transactionHash", transactionHash => {
        this.setState({
          transactionHash,
          transactionIsPending: true,
          contractTransaction: null,
          transactionError: null
        });
      })
      .on("receipt", receipt => {
        this.setState({
          contractTransaction: receipt,
          transactionIsPending: false,
          transactionError: null
        });
      })
      .on("error", error => {
        this.setState({
          contractTransaction: null,
          transactionIsPending: false,
          transactionError: error
        });
      });
  };

  renderForm = () => {
    const { template } = this.props;

    return (
      <FormGenerator ABI={template.ABI} formSubmit={this.handleFormSubmit} />
    );

    // if (template.hasUI) {
    //   return (
    //     <FormGenerator ABI={template.ABI} formSubmit={this.handleFormSubmit} />
    //   );
    // } else {
    //   return (
    //     <Typography>
    //       This template has no corresponding UI form.
    //     </Typography>
    //   );
    // }
  };

  renderTransactionResult = () => {
    const { contractTransaction } = this.state;
    // console.log("contractTransaction", contractTransaction);

    const stringifyValue = value => {
      if (_.isNull(value)) {
        return "null";
      } else if (_.isObject(value)) {
        return JSON.stringify(value);
      }

      return value.toString();
    };
    // return JSON.stringify(contractTransaction);
    return Object.keys(contractTransaction).map((key, i) => (
      <Typography
        style={{ wordWrap: "break-word" }}
        gutterBottom
        key={i}
        align="left"
      >
        <b>{key.toString()}:</b> {stringifyValue(contractTransaction[key])}
      </Typography>
    ));
  };

  render() {
    const { template } = this.props;

    if (!template) {
      return (
        <Typography variant="h5" gutterBottom>
          No template demo available.
        </Typography>
      );
    }

    if (!template.demoAddress) {
      return (
        <Typography variant="h5" gutterBottom>
          No template demo available. Has not yet been deployed to BXS Testnet.
        </Typography>
      );
    }

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5" gutterBottom align="center">
              Demo Form
            </Typography>
            {this.renderForm()}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ padding: 20, textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              Receipt
            </Typography>
            {!this.state.contractTransaction && (
              <Typography gutterBottom>
                Ropsten Test Blockchain transaction result for the test will
                show up here once you submit the form.
              </Typography>
            )}
            {/* {this.state.transactionHash && (
              <span>
                <Typography gutterBottom>
                  View this transaction on Etherscan:
                </Typography>
                <Typography
                 
                  style={{ wordWrap: "break-word" }}
                  gutterBottom
                >
                  <a
                    href={`https://ropsten.etherscan.io/tx/${
                      this.state.transactionHash
                    }`}
                    target="_blank"
                  >
                    https://ropsten.etherscan.io/tx/
                    {this.state.transactionHash}
                  </a>
                </Typography>
              </span>
            )} */}

            {this.state.transactionIsPending && (
              <Paper style={{ padding: 20 }} elevation={0}>
                <Typography gutterBottom>Transaction processing.</Typography>
                <Typography gutterBottom>
                  This may take up to 5 minutes.
                </Typography>
                <LinearProgress color="primary" />
              </Paper>
            )}

            {this.state.contractTransaction && (
              <Paper style={{ padding: 20 }} elevation={0}>
                {this.props.template._id === "5b47c2a3a595aee5d8669086" && (
                  <Typography
                    style={{ wordWrap: "break-word" }}
                    gutterBottom
                    key={"walletAddress"}
                    align="left"
                  >
                    wallet address: 0x6c30B4E7c324abF5c4201315191De0942ba54921
                  </Typography>
                )}
                {this.renderTransactionResult()}
              </Paper>
            )}

            {this.state.transactionError && (
              <Paper style={{ padding: 20, marginTop: 10 }} elevation={1}>
                <Typography>{this.state.transactionError.message}</Typography>
              </Paper>
            )}

            {this.state.contractTransaction &&
              this.props.template._id === "5b47c2a3a595aee5d8669086" && (
                <Button
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={"/workflows/gig-worker"}
                  target="_blank"
                >
                  Gig Worker Onboarding Workflow
                </Button>
              )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    web3: state.web3.instance
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateDemo);
