import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Workflows from "./Workflows";

const styles = {
  root: {
    maxWidth: 940,
    margin: "0 auto"
  }
};

class Dashboard extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h3" color="primary" gutterBottom>
          My Studio
        </Typography>
        <Workflows />
      </div>
    );
  }
}

export default withStyles(styles)(Dashboard);
