import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";
import Utils from "./Utils";
import { passwordResetsActions } from "../../actions";

const styles = theme => ({});

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      email: ""
    };
  }

  handleFormSubmit = event => {
    event.preventDefault();
    this.setState({ submitted: true });
    this.props.createPasswordReset({ email: this.state.email });
  };

  render() {
    if (this.state.submitted) {
      return (
        <Typography gutterBottom>
          Check your email! We've sent you a link to reset your password.
        </Typography>
      );
    }

    return (
      <form onSubmit={this.handleFormSubmit}>
        <Typography gutterBottom>
          Enter the email you use to log into BLOCKxStudio and we'll send you a
          link to reset your password.
        </Typography>
        <TextField
          key="email"
          id="email"
          label="Email"
          autoComplete="email"
          type="text"
          onChange={event =>
            this.setState({
              email: event.target.value
            })
          }
          fullWidth
          margin="normal"
          required
        />
        <Button
          type="submit"
          color="primary"
          style={{ marginTop: 20 }}
          variant="contained"
          disabled={!Utils.isEmailValid(this.state.email)}
        >
          Email Me a Reset Link
        </Button>
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createPasswordReset: passwordResetsActions.createPasswordReset
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ForgotPasswordForm));
