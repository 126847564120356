import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ListTemplates from "../templates/ListTemplates";

const styles = theme => ({
  link: {
    color: theme.palette.primary.main
  },
  titleBox: theme.page.title
});

class AllTemplates extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ maxWidth: 940, margin: "0 auto" }}>
        <div className={classes.titleBox}>
          <Typography variant="h3" color="primary" gutterBottom>
            Smart Contract Templates
          </Typography>
          <Typography variant="h5" color="secondary" gutterBottom>
            Simplify your enterprise's start with blockchain distributed
            applications with these smart contract templates. If you're a smart
            contract developer,{" "}
            <Link to={"/contact"} className={classes.link}>
              contact us
            </Link>{" "}
            about joining the BLOCKxStudio developer community.
          </Typography>
        </div>
        <ListTemplates />
      </div>
    );
  }
}

export default withStyles(styles)(AllTemplates);
