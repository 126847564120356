import axios from "axios";
import { BXSAPIURL, cookies } from "../constants";

// Types
export const organizationsConstants = {
  GET_ORGANIZATIONS_REQUEST: "GET_ORGANIZATIONS_REQUEST",
  GET_ORGANIZATIONS_SUCCESS: "GET_ORGANIZATIONS_SUCCESS",
  GET_ORGANIZATIONS_FAILURE: "GET_ORGANIZATIONS_FAILURE",

  GET_ORGANIZATION_REQUEST: "GET_ORGANIZATION_REQUEST",
  GET_ORGANIZATION_SUCCESS: "GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAILURE: "GET_ORGANIZATION_FAILURE",

  UPDATE_ORGANIZATION_REQUEST: "UPDATE_ORGANIZATION_REQUEST",
  UPDATE_ORGANIZATION_SUCCESS: "UPDATE_ORGANIZATION_SUCCESS",
  UPDATE_ORGANIZATION_FAILURE: "UPDATE_ORGANIZATION_FAILURE",

  CREATE_ORGANIZATION_REQUEST: "CREATE_ORGANIZATION_REQUEST",
  CREATE_ORGANIZATION_SUCCESS: "CREATE_ORGANIZATION_SUCCESS",
  CREATE_ORGANIZATION_FAILURE: "CREATE_ORGANIZATION_FAILURE"
};

// Creators
export const organizationsActions = {
  getOrganization,
  getOrganizations,
  updateOrganization,
  createOrganization
};

// Implementations
function getOrganizations() {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: "/organizations",
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: organizationsConstants.GET_ORGANIZATIONS_REQUEST };
  }
  function success(payload) {
    return { type: organizationsConstants.GET_ORGANIZATIONS_SUCCESS, payload };
  }
  function failure() {
    return { type: organizationsConstants.GET_ORGANIZATIONS_FAILURE };
  }
}

function getOrganization(id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "get",
      url: `/organizations/${id}`,
      baseURL: BXSAPIURL
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: organizationsConstants.GET_ORGANIZATION_REQUEST };
  }
  function success(payload) {
    return { type: organizationsConstants.GET_ORGANIZATION_SUCCESS, payload };
  }
  function failure() {
    return { type: organizationsConstants.GET_ORGANIZATION_FAILURE };
  }
}

function updateOrganization(fields, id) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "put",
      url: `/organizations/${id}`,
      baseURL: BXSAPIURL,
      data: fields,
      headers: { "x-access-token": cookies.get("token") }
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: organizationsConstants.UPDATE_ORGANIZATION_REQUEST };
  }
  function success(payload) {
    return {
      type: organizationsConstants.UPDATE_ORGANIZATION_SUCCESS,
      payload
    };
  }
  function failure() {
    return { type: organizationsConstants.UPDATE_ORGANIZATION_FAILURE };
  }
}

function createOrganization(values) {
  return dispatch => {
    dispatch(request());

    axios({
      method: "post",
      url: "/organizations",
      baseURL: BXSAPIURL,
      data: values
    })
      .then(res => {
        if (res.data.success) {
          dispatch(success(res.data.payload));
        } else {
          dispatch(failure());
          console.log(res.data.message);
        }
      })
      .catch(error => {
        dispatch(failure());
        console.log(error.response);
      });
  };

  function request() {
    return { type: organizationsConstants.CREATE_ORGANIZATION_REQUEST };
  }
  function success(payload) {
    return {
      type: organizationsConstants.CREATE_ORGANIZATION_SUCCESS,
      payload
    };
  }
  function failure() {
    return { type: organizationsConstants.CREATE_ORGANIZATION_FAILURE };
  }
}
