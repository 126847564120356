import { templatesConstants } from "../actions";

const TEMPLATES_INITIAL = {
  template: null,
  templates: null,
  isGettingTemplate: false,
  isGettingTemplates: false
};

export const templatesReducer = (state = TEMPLATES_INITIAL, action) => {
  switch (action.type) {
    case templatesConstants.GET_TEMPLATE_REQUEST:
      return {
        ...state,
        isGettingTemplate: true
      };
    case templatesConstants.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: action.payload,
        isGettingTemplate: false
      };
    case templatesConstants.GET_TEMPLATE_FAILURE:
      return {
        ...state,
        template: null,
        isGettingTemplate: false
      };

    case templatesConstants.GET_TEMPLATES_REQUEST:
      return {
        ...state,
        isGettingTemplates: true
      };
    case templatesConstants.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload,
        isGettingTemplates: false
      };
    case templatesConstants.GET_TEMPLATES_FAILURE:
      return {
        ...state,
        templates: null,
        isGettingTemplates: false
      };

    default:
      return state;
  }
};
