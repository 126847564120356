import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  box: theme.page.box,
  title: theme.page.title,
  section: theme.page.title
});

class TermsConditions extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.box}>
        <div className={classes.title}>
          <Typography variant="h3" color="primary" gutterBottom>
            BLOCKxStudio.com and User Agreement
          </Typography>
          <Typography variant="h5" color="secondary" gutterBottom>
            Welcome to BLOCKxStudio.com. The BLOCKxStudio.com website (the
            "Site") is comprised of various web pages operated by Last Myle,
            LLC. BLOCKxStudio.com is offered to you conditioned on your
            acceptance without modification of the terms, conditions, and
            notices contained herein (the "Terms"). Your use of BLOCKxStudio.com
            website constitutes your agreement to such Terms. Please read these
            terms carefully and keep a copy of them for your reference.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Privacy
          </Typography>
          <Typography gutterBottom>
            Your use of BLOCKxStudio.com is subject to Last Myle, LLC’s Privacy
            Policy. Please review our Privacy Policy, which also governs the
            Site and informs users of our data collection practices.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Electronic Communication
          </Typography>
          <Typography gutterBottom>
            Visiting BLOCKxStudio.com or sending emails to BLOCKxStudio.com
            constitutes electronic communications. You consent to receive
            electronic communications and you agree that all agreements,
            notices, disclosures and other communications that we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Your Account
          </Typography>
          <Typography gutterBottom>
            If you use the Site, you are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your computer, and you agree to accept responsibility for
            all activities that occur under your account or password. You may
            not assign or otherwise transfer your account to any other person or
            entity. You acknowledge that Last Myle, LLC is not responsible for
            third party access to your account that results from theft or
            misappropriation of your account. Last Myle, LLC and its associates
            reserve the right to refuse or cancel service, terminate accounts,
            or remove or edit content in our sole discretion.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Children Under Thirteen
          </Typography>
          <Typography gutterBottom>
            We provide information about your personal data practices for
            children on our home page and wherever we knowingly collect personal
            data from children on our Site. If you are under the age of
            thirteen, you must ask your parent or legal guardian for permission
            to use the Site. If you are a parent or legal guardian and have
            questions regarding our data collection practices, please contact us
            using the information provided at the end of this Agreement. If you
            are under the age of eighteen, you may use the Site with the
            permission of a parent or legal guardian.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Links to Third Party Sites/ Third Party Services
          </Typography>
          <Typography gutterBottom>
            BLOCKxStudio.com may contain links to other websites ("Linked
            Sites"). The Linked Sites are not under the control of Last Myle,
            LLC and Last Myle, LLC is not responsible for the contents of any
            Linked Sites, including without limitation any link contained in a
            Linked Site, or any changes or updates to a Linked Site. Last Myle,
            LLC is providing these links to you as a convenience, and the
            inclusion of any link does not imply endorsement by Last Myle, LLC
            of any Linked Site or any association with its operators or owners.
          </Typography>
          <Typography gutterBottom>
            Certain services made available via BLOCKxStudio.com are delivered
            by third party websites and organizations. By using any product,
            service or functionality originating from the BLOCKxStudio.com
            domain, you hereby acknowledge and consent that Last Myle, LLC may
            share such information and data with any third party with whom Last
            Myle, LLC has a contractual relationship to provide the requested
            product, service or functionality on behalf of BLOCKxStudio.com
            users and customers.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            No Unlawful or Prohibited Use/ Intellectual Property
          </Typography>
          <Typography gutterBottom>
            You are granted a non-exclusive, non-transferable license to access
            and use BLOCKxStudio.com strictly in accordance with these terms of
            use. As a condition of your use of the Site, you warrant to Last
            Myle, LLC that you will not use the Site for any purpose that is
            unlawful or prohibited by these Terms. You may not use the Site in
            any manner which could damage, disable, overburden, or impair the
            Site or interfere with any other party’s use and enjoyment of the
            Site. You may not obtain or attempt to obtain any materials or
            information through any means not intentionally made available or
            provided for through the Site.
          </Typography>
          <Typography gutterBottom>
            All content included as part of the Service, such as text, graphics,
            logos, images, as well as compilation thereof, and any software used
            on the Site, is the property of Last Myle, LLC or its suppliers and
            protected by copyright and other laws that protect intellectual
            property and proprietary rights. You agree to observe and abide by
            all copyright and other proprietary notices. Legends or other
            restrictions contained in any such manner and will not make any
            changes thereto.
          </Typography>
          <Typography gutterBottom>
            You will not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the content, in whole or in part, found on
            the Site. Last Myle.co content is not for sale. Your use of the Site
            does not entitle you to make any unauthorized use of any protected
            content, and in particular you will not delete or alter any
            proprietary rights or attribution notices in any content. You will
            use protected content solely for your personal use and will make no
            other use of the content without the express written permission of
            Last Myle, LLC and the copyright owner. You agree that you do not
            acquire any ownership rights in any protected content. We do not
            grant you any licenses, express or implied, to the intellectual
            property of Last Myle, LLC or our licensors except as expressly
            authorized by these Terms.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Use of Communication Services
          </Typography>
          <Typography gutterBottom>
            The Site may contain bulletin board services, chat areas, news
            groups, forums, communities, personal web pages, calendars, and/or
            other message or communication facilities designed to enable you to
            communicate with the public at large or with a group (collectively,
            "Communication Services"). You agree to use the Communication
            Services only to post, send and receive messages and material that
            are proper and related to the particular Communication Service.
          </Typography>
          <Typography gutterBottom>
            By way of example, and not as a limitation, you agree that when
            using a Communication Service, you will not: defame, abuse, harass,
            stalk, threaten or otherwise violate the legal rights (such rights
            of privacy and publicity) of others; publish, post, upload,
            distribute or disseminate any inappropriate, profane, defamatory,
            infringing, obscene, indecent or unlawful topic, name, material or
            information; upload files that contain software or other material
            protected by intellectual property laws (or by rights of privacy of
            publicity) unless you own or control the rights thereto or have
            received all necessary consent; upload files that contain viruses,
            corrupted files, or any other similar software or programs that may
            damage the operation of another’s computer; advertise or offer to
            sell or buy any goods or services for any business purpose, unless
            such Communication Service specifically allows such messages;
            conduct or forward surveys, contests, pyramid schemes or chain
            letters; download any file posted by another user of a Communication
            Service that you know, or reasonably should know, cannot be legally
            distributed in such manner; falsify or delete any author
            attributions, legal or other proper notices or proprietary
            designations or labels of the origin or source of software or other
            material contained in a file that is uploaded; restrict or inhibit
            any other user from using and enjoying the Communication Service;
            violate any code of conduct or other guidelines which may be
            applicable for any particular Communication Service; harvest or
            otherwise collect information about others, including email
            addresses, without their express written consent; violate any
            applicable laws or regulations.
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC has no obligation to monitor any Communication
            Service. However, Last Myle, LLC reserves the right to review
            materials posted to a Communication Service and to remove any
            materials in its sole discretion. Last Myle, LLC reserves the right
            to terminate your access to any Communication Service at any time
            without prior notice for any reason whatsoever.
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC reserves the right at all ties to disclose any
            information as necessary to satisfy any applicable law, regulation,
            legal process or governmental request, or to edit, refuse to post or
            to remove any information or materials, in whole or in part, in Last
            Myle, LLC’s sole discretion.
          </Typography>
          <Typography gutterBottom>
            Always use caution when giving out any personally identifying
            information about yourself, your children or any family members in
            any Communication Service. Last Myle, LLC does not control or
            endorse the content, messages or information found in any
            Communication Service and, therefore, specifically disclaims any
            liability with regard to any Communication Service and any actions
            resulting from your participation in any Communication Service.
            Managers and hosts are not authorized Last Myle, LLC spokespersons,
            and their views do not necessarily reflect those of Last Myle, LLC.
          </Typography>
          <Typography gutterBottom>
            Materials uploaded to any Communication Service may be subject to
            posted limitations on usage, reproduction and/or dissemination. You
            are responsible for adhering to such limitations if you upload the
            materials.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Materials Provided to Last Myle, LLC or Posted on Any
            BLOCKxStudio.comm Web Page
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC does not claim ownership of the materials you provide
            to Last Myle, LLC (including feedback and suggestions) or post,
            upload, input or submit to any Last Myle, LLC related website or our
            associated services (collectively, "Submissions"). However, by
            posting, uploading, inputing, providing or submitting your
            Submissions you are granting Last Myle, LLC, our affiliated
            companies and necessary sublicensees permission to use your
            Submissions in connection with the operation of their Internet
            businesses including, without limitation, the rights to: copy,
            distribute, transmit, publicly display, publicly perform, reproduce,
            edit, translate and reformat your Submissions; and to publish your
            name in connection with your Submissions.
          </Typography>
          <Typography gutterBottom>
            No compensation will be paid with respect to the use of your
            Submissions, as provided herein. Last Myle, LLC is under no
            obligation to post or use any Submissions you may provide and may
            remove any Submissions at any time in Last Myle, LLC’s sole
            discretion.
          </Typography>
          <Typography gutterBottom>
            By posting, uploading, inputting, providing or submitting your
            Submissions you warrant and represent that you own or otherwise
            control all of the rights to your Submissions as described in this
            section including, without limitation, all the rights necessary for
            you to provide, post,upload, input or submit the Submissions.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Third Party Accounts
          </Typography>
          <Typography gutterBottom>
            You will be able to connect your BLOCKxStudio.com account to third
            party accounts. By connecting your BLOCKxStudio.com account to your
            third-party account, you acknowledge and agree that you are
            consenting to the continuous release of information about you to
            others (in accordance with your privacy settings on those
            third-party sites). If you do not want information about you to be
            shared in this manner, do not use this feature.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            International Users
          </Typography>
          <Typography gutterBottom>
            The Service is controlled, operated and administered by Last Myle,
            LLC from our offices within the United States of America. If you
            access the Service from a location outside of the United State of
            America, you are responsible for compliance with all local laws. You
            agree that you will not use the BLOCKxStudio.com Content accessed
            through BLOCKxStudio.com in any country or in any manner prohibited
            by any applicable laws, restrictions or regulations.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Indemnification
          </Typography>
          <Typography gutterBottom>
            You agree to indemnify, defend and hold harmless Last Myle, LLC, its
            officers, directors, employees, agents and third parties, for any
            losses, costs, liabilities and expenses (including reasonable
            attorney’s fees) relating to or arising out of your use of or
            inability to use the Site or services, any user posting made by you,
            your violation of any terms of this Agreement or your violation of
            any rights of a third party, or your violation of any applicable
            laws, rules or regulations. Last Myle, LLC reserves the right, at
            its cost, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will
            fully cooperate with Last Myle, LLC in asserting any available
            defenses.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Arbitration
          </Typography>
          <Typography gutterBottom>
            In the event the parties are not able to resolve any dispute between
            them arising out of or concerning these Terms and Conditions, or any
            provisions hereof, whether in contract, tort, or otherwise at law or
            in equity for damages or any other relief, then such dispute shall
            be resolved only by final and binding arbitration pursuant to the
            Federal Arbitration Act, conducted by a single neutral arbitrator
            and administered by the American Arbitration Association, or similar
            arbitration service selected by the parties, in a location mutually
            agreed upon by the parties. The arbitrator’s award shall be final,
            and judgment may be entered upon it in any court having
            jurisdiction. In the event that any legal or equitable action,
            proceeding or arbitration arises out of or concerns these Terms and
            Condition, the prevailing party shall be entitled to recover its
            costs and reasonable attorney’s fees. These parties agree to
            arbitrate all disputes and claims that are a result of these Terms
            and Conditions. The parties agree that the Federal Arbitration Act
            governs the interpretation and enforcement of this provision. The
            entire dispute, including the scope and enforceability of this
            arbitration provision shall be determined by the Arbitrator. This
            arbitration shall survive the termination of these Terms and
            Conditions.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Class Action Waiver
          </Typography>
          <Typography gutterBottom>
            Any arbitration under these Terms and Conditions will take place on
            an individual basis; class arbitrations and
            class/representative/collective actions are not permitted. THE
            PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY
            IN EACH’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PUNITIVE CLASS, COLLECTIVE AND/OR REPRESENTATIVE
            PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION
            AGAINST THE OTHER. Further, unless both you and Last Myle, LLC agree
            otherwise, the arbitrator may not consolidate more than one person’s
            claims, and may not otherwise preside over any form of a
            representative or class proceeding.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Liability Disclaimer
          </Typography>
          <Typography gutterBottom>
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
            Last Myle, LLC AND/OR SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES
            IN THE SITE AT ANY TIME.
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT
            THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
            OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
            GRAPHICS CONTAINED ON THE SITE FOR ANY SOFTWARE, PRODUCTS, SERVICES
            AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR
            CONDITION OF ANY KIND. Last Myle, LLC AND/OR ITS SUPPLIERS HEREBY
            DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
            INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          </Typography>
          <Typography gutterBottom>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            Last Myle, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
            INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR
            ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
            LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY
            TO USE THE SITE OR RELATED PRODUCTS, THE PROVISION OF OR FAILRE TO
            PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
            SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR
            OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON
            CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
            OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF
            DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE
            EXLCUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
            INCIDENTIAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF
            YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF
            THESE TERMS OF USE, YOUR SOLE AND EXLCUSIVE REMEDY IS TO DISCONTINUE
            USING THE SITE.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Termination/ Access Restriction
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC reserves the right in its sole discretion, to
            terminate your access to the Site and the related services or any
            potion thereof at any time, without notice. To the maximum extent
            permitted by law, this agreement is governed by the laws of the
            state of Washington and you hereby consent to the exclusive
            jurisdiction and venue of courts in King County in all disputes
            arising out of or relating to the use of the Site. Use of the Site
            is unauthorized in any jurisdiction that does not give effect to all
            provisions of these Terms, including, without limitation, this
            section.
          </Typography>
          <Typography gutterBottom>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and Last Myle, LLC as a result of
            this agreement or use of the Site. Last Myle, LLC’s performance of
            this agreement is subject to existing laws and legal process, and
            nothing contained in this agreement is in derogation of Last Myle,
            LLC’s right to comply with governmental, court and law enforcement
            requests or requirements relating to your use of the Site or
            information provided to or gathered by Last Myle, LLC with respect
            to such use. If any part of this agreement is determined to be
            invalid or unenforceable pursuant to applicable law including, but
            not limited to, the warranty disclaimers and liability limitations
            set forth above, then the invalid or unenforceable provision will be
            deemed superseded by a valid, enforceable provision that most
            closely matches the intent of the original provision and the
            remainder of the agreement shall continue in effect.
          </Typography>
          <Typography gutterBottom>
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and Last Myle, LLC with respect to
            the Site and it supersedes all prior or contemporaneous
            communications and proposals, whether electronic, oral or written,
            between the user and Last Myle, LLC with respect to the Site. A
            printed version of this agreement and of any notice given in
            electronic form shall be admissible in judicial or administrative
            proceedings based upon or relating to this agreement to the same
            extent and subject to the same conditions as other business
            documents and records originally generated and maintained in printed
            form. It is the express wish to the parties that this agreement and
            all related documents be written in English.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Changes to Terms
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC reserves the right, in its sole discretion, to change
            the Terms under which BLOCKxStudio.com is offered. The most current
            version of the Terms will supersede all previous versions. Last
            Myle, LLC encourages you to periodically review the Terms to stay
            informed of our updates.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Contact Information
          </Typography>
          <Typography gutterBottom>
            Last Myle, LLC welcomes your questions or comments regarding its
            Terms and Conditions. If you believe that Last Myle LLC has not
            adhered to its Terms and Conditions, please contact Bernard Hansen
            at:
          </Typography>
          <Typography gutterBottom>Last Myle, LLC</Typography>
          <Typography gutterBottom>220 Second Avenue</Typography>
          <Typography gutterBottom>Seattle, WA 98104</Typography>
        </div>
        <div className={classes.section}>
          <Typography gutterBottom>
            Email address: bernie@lastmyle.co
          </Typography>
          <Typography gutterBottom>Telephone number: 206-499-3342</Typography>
          <Typography gutterBottom>Effective June 6, 2018</Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TermsConditions);
