import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { gigWorkerActions } from "../../../../actions";

const styles = {
  root: {
    padding: 30,
    margin: "40px 20px",
    textAlign: "center"
  },
  title: {},
  form: {
    maxWidth: 400,
    margin: "0 auto",
    padding: "20px 20px 60px"
  },
  button: {
    margin: "0px auto 20px"
  }
};

class Verification extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      name: props.worker.name,
      phone: props.worker.phone,
      message: `Message from BLOCKxStudio: ${
        props.worker.name
      }, verify your identity and opt into receiving messages. Reply YES / NO`
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        {this.title()}
        {this.form()}
        {this.button()}
      </Paper>
    );
  }

  title() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4" color="primary" className={classes.title}>
          Verify Gig Worker
        </Typography>
        <Typography variant="subtitle1">Admin View</Typography>
      </Fragment>
    );
  }

  form() {
    const { classes } = this.props;

    return (
      <div className={classes.form}>
        <TextField
          key="name"
          id="name"
          label="Name"
          autoComplete="name"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.name}
          onChange={event => {
            this.setState({
              name: event.target.value
            });
          }}
        />
        <TextField
          key="phone"
          id="phone"
          label="Phone Number"
          autoComplete="phone"
          type="text"
          fullWidth
          margin="normal"
          defaultValue={this.state.phone}
          onChange={event => {
            this.setState({
              phone: event.target.value
            });
          }}
        />
        <TextField
          key="message"
          id="message"
          label="Message Number"
          autoComplete="message"
          type="text"
          fullWidth
          margin="normal"
          multiline
          defaultValue={this.state.message}
          onChange={event => {
            this.setState({
              message: event.target.value
            });
          }}
        />
      </div>
    );
  }

  button() {
    const { classes } = this.props;

    return (
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        component={Link}
        to="/workflows/gig-worker/1"
      >
        Send SMS Verification
      </Button>
    );
  }
}

const mapStateToProps = state => {
  return {
    worker: state.gigWorker.worker
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateWorker: gigWorkerActions.updateWorker
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Verification));
