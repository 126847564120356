import qs from "qs";
import emailRegex from "email-regex";

export default class Utils {
  static parseLocation(location) {
    return location.search.includes(`?`)
      ? qs.parse(location.search.split("?").pop())
      : [];
  }

  static parseLocationByKey(location, key) {
    const parsed = Utils.parseLocation(location);

    return parsed ? parsed[key] : null;
  }

  static isEmailValid(email) {
    return emailRegex({ exact: true }).test(email);
  }

  static stringToBoolean(string) {
    return string === "true" || string === "yes";
  }

  static parse(string) {
    return qs.parse(string);
  }

  static stringify(object) {
    return qs.stringify(object);
  }
}
