import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Divider,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { ROLES } from "../../constants/Roles";

const styles = {
  divider: { marginTop: 16, marginBottom: 16 }
};

class RequestCard extends Component {
  static protoTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleApprove: PropTypes.func.isRequired,
    handleDecline: PropTypes.func.isRequired,
    request: PropTypes.object
  };

  static defaultProps = {
    request: {}
  };

  state = {
    role: null
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="invite-request-details"
      >
        <DialogTitle id="invite-request-name">
          {this.props.request.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>
            {this.props.request.message}
          </DialogContentText>
          <Divider className={classes.divider} />
          <DialogContentText variant="subtitle1" gutterBottom>
            {this.props.request.email}
          </DialogContentText>
          <DialogContentText variant="subtitle1" gutterBottom>
            {moment(this.props.request.timestamp).format("LLL")}
          </DialogContentText>
          <Divider className={classes.divider} />
          <FormLabel component="legend" className={classes.rolesFormLabel}>
            Choose Role for this User
          </FormLabel>
          <RadioGroup
            aria-label="roles"
            name="roles"
            value={this.state.role}
            onChange={event => this.setState({ role: event.target.value })}
          >
            {ROLES.filter(role =>
              ["bxs-admin", "bxs-developer", "customer-owner"].includes(role.id)
            ).map(role => (
              <FormControlLabel
                key={role.id}
                value={role.id}
                control={<Radio color="primary" />}
                label={role.name}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this.props.handleApprove(this.state.role)}
            disabled={!this.state.role}
          >
            Approve
          </Button>
          <Button color="secondary" onClick={this.props.handleDecline}>
            Decline
          </Button>
          <Button color="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    internalRole: state.auth.user.internalRole
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestCard));
