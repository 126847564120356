import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button } from "@material-ui/core";
import { invitesActions } from "../../actions";

const styles = theme => ({});

class RequestForm extends Component {
  state = {
    form: {}
  };

  handleFormSubmit = event => {
    event.preventDefault();
    this.props.sendRequest({
      name: this.state.form.name,
      email: this.state.form.email,
      message: this.state.form.message
    });
  };

  render() {
    return (
      <Fragment>
        <Typography gutterBottom>
          Enter your name and email and we will send you an email to register
          your account after approval!
        </Typography>
        <form onSubmit={this.handleFormSubmit}>
          <TextField
            required
            key="name"
            id="name"
            label="Full Name"
            autoComplete="name"
            type="text"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  name: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            required
            key="email"
            id="email"
            label="Email"
            autoComplete="email"
            type="text"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  email: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            required
            key="message"
            id="message"
            label="Message"
            autoComplete="message"
            type="message"
            onChange={event =>
              this.setState({
                form: {
                  ...this.state.form,
                  message: event.target.value
                }
              })
            }
            fullWidth
            margin="normal"
            helperText="Please include the name of your organization and describe what brings you to BLOCKxStudio."
          />
          <Button
            type="submit"
            color="primary"
            style={{ marginTop: 20 }}
            variant="contained"
            load
          >
            Request an Invite
          </Button>
        </form>
      </Fragment>
    );
  }
}

RequestForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendRequest: invitesActions.sendRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestForm));
